<template>
  <div class="ygyk">
    <div class="ygyk_jyzx">
      <div class="ygyk_cnt">
        <div class="content_tit">
          <div>
            <img src="https://downloads.whzhjy.cn/desktop/image/ygyk_icon.png" alt="" />
            <div class="data_type">
              <div
                v-for="item in dataTypeList"
                :key="item.val"
                :class="dataAct === item.val ? 'typeAct' : ''"
                @click="cgDataType('yk', item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="more" @click="ygyk_open('')">
            <img src="https://downloads.whzhjy.cn/desktop/image/more.png" alt="" />
          </div>
        </div>
        <div class="ygyk_cnt_btm" v-if="ygyk_data.length > 0">
          <el-carousel class="home_carousel_5" indicator-position="none" arrow="never">
            <el-carousel-item v-for="item in ygyk_data.slice(0, 6)" :key="item.id">
              <div class="ygyk_cnt_btm_left" @click="ygyk_open(item.id)">
                <img
                  :src="
                    item.img
                      ? item.img
                      : 'https://downloads.whzhjy.cn/desktop/image/ygyk-default.png'
                  "
                  alt=""
                />
                <div>
                  <p class="overText" :title="item.courseTitle">{{ item.courseTitle }}</p>
                  <p>{{ item.publishTime }}</p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="ygyk_cnt_btm_right">
            <div
              v-for="(item, index) in ygyk_data.slice(0, 6)"
              :key="index"
              class="ygyk_cnt_btm_right_item"
              @click="ygyk_open(item.id)"
            >
              <div>
                <span v-if="index === 0"
                  ><img
                    src="https://downloads.whzhjy.cn/desktop/image/ygyk_pm_1.png"
                    alt=""
                /></span>
                <span v-if="index === 1"
                  ><img
                    src="https://downloads.whzhjy.cn/desktop/image/ygyk_pm_2.png"
                    alt=""
                /></span>
                <span v-if="index === 2"
                  ><img
                    src="https://downloads.whzhjy.cn/desktop/image/ygyk_pm_3.png"
                    alt=""
                /></span>
                <span v-if="index > 2" class="last_pm">{{ index + 1 }}</span>
              </div>
              <div>
                <p class="overText" :title="item.courseTitle">{{ item.courseTitle }}</p>
                <p>{{ item.publishTime }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="noData" v-else></div>
      </div>
    </div>
    <div class="ygyk_jsfc">
      <div class="ygyk_jsfc_cnt">
        <div class="content_tit">
          <div>
            <img
              src="https://downloads.whzhjy.cn/desktop/image/ygyk_jsfc_icon.png"
              alt=""
            />
            <div class="data_type">
              <div
                v-for="item in jsDataTypeList"
                :key="item.val"
                :class="jsDataAct === item.val ? 'typeAct' : ''"
                @click="cgDataType('js', item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div
            class="more"
            @click="
              open(
                0,
                `/manage/login_excellentTeacherList.action${
                  jsDataAct === 'zyjs' ? '?teacherType=2' : ''
                }`
              )
            "
          >
            <img src="https://downloads.whzhjy.cn/desktop/image/more.png" alt="" />
          </div>
        </div>
        <div class="jsfc_sw">
          <div class="swiper-container" id="swiper_container_one">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide tch_card"
                v-for="(item, index) in yk_th"
                :key="'0' + index"
                @click="
                  open(0, `/manage/login_excellentTeacherDetails.action?id=${item.id}`)
                "
              >
                <img :src="item.photoUrlOrg ? item.photoUrlOrg : item.photoUrl" alt="" />
                <p class="th_name">
                  {{ item.teacherName }}
                  <span class="overText" :title="item.schoolName">{{
                    item.schoolName
                  }}</span>
                </p>
                <div class="th_hv">
                  <p>{{ item.teacherName }}</p>
                  <p class="overText" :title="item.schoolName">{{ item.schoolName }}</p>
                  <p>{{ item.teacherInfo }}...</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="swiper-button-prev swiper-button-prev-one swiper-button-prev-b"
            slot="button-prev"
          >
            <i class="el-icon-arrow-left"></i>
          </div>
          <div
            class="swiper-button-next swiper-button-next-one swiper-button-next-b"
            slot="button-next"
          >
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import dateFormat from "../../utils/dateFormat.js";
import { checkUserRight } from "../../utils/openPage.js";
import logMix from "../../mixin/index.js";
export default {
  name: "ygyk",
  props: {},
  components: {},
  mixins: [logMix],
  data() {
    return {
      dataAct: "tbkc",
      dataTypeList: [
        {
          name: "同步课程",
          val: "tbkc",
        },
        {
          name: "专题课程",
          val: "ztkc",
        },
      ],
      jsDataAct: "ykjs",
      jsDataTypeList: [
        {
          name: "阳光云课名师",
          val: "ykjs",
        },
        {
          name: "卓越教师",
          val: "zyjs",
        },
        // {
        //   name: '骨干教师',
        //   val: 'gg',
        // }
      ],
      ygykAllData: {},
      ygyk_data: [
        {
          id: 7073,
          yid: 6057,
          thumbnail: null,
          lessonTypeName: null,
          courseTitle: "百分率",
          vcount: 669,
          publishTime: "2022-09-01",
        },
        {
          id: 7072,
          yid: 6093,
          thumbnail: null,
          lessonTypeName: null,
          courseTitle: "倒数的认识",
          vcount: 1962,
          publishTime: "2022-09-01",
        },
        {
          id: 7071,
          yid: 6409,
          thumbnail: null,
          lessonTypeName: null,
          courseTitle: "耐久跑-弯道跑技术",
          vcount: 794,
          publishTime: "2022-08-09",
        },
        {
          id: 7070,
          yid: 6409,
          thumbnail: null,
          lessonTypeName: null,
          courseTitle: "耐久跑-发展心肺耐力的多种练习方法",
          vcount: 148,
          publishTime: "2022-08-09",
        },
        {
          id: 7064,
          yid: 5993,
          thumbnail: null,
          lessonTypeName: null,
          courseTitle: "熊陈俊：精读《宝玉挨打》",
          vcount: 246,
          publishTime: "2022-08-01",
        },
        {
          id: 7063,
          yid: 6023,
          thumbnail: null,
          lessonTypeName: null,
          courseTitle: "小篮球：原地双手胸前传接球",
          vcount: 230,
          publishTime: "2022-07-30",
        },
      ],
      yk_th: [],
      tchList: [
        {
          head: "https://downloads.whzhjy.cn/desktop/image/tch_head.png",
          name: "​耿昕蓉",
          school: "芜湖市第二十八中学",
          summary:
            "耿昕蓉，数学教师，一级教师，中共党员，镜湖区骨干教师、镜湖区师德先进个人、镜湖区优秀共产党员…",
        },
        {
          head: "https://downloads.whzhjy.cn/desktop/image/tch_head.png",
          name: "​耿昕蓉",
          school: "芜湖市第二十八中学",
          summary:
            "耿昕蓉，数学教师，一级教师，中共党员，镜湖区骨干教师、镜湖区师德先进个人、镜湖区优秀共产党员…",
        },
        {
          head: "https://downloads.whzhjy.cn/desktop/image/tch_head.png",
          name: "​耿昕蓉",
          school: "芜湖市第二十八中学",
          summary:
            "耿昕蓉，数学教师，一级教师，中共党员，镜湖区骨干教师、镜湖区师德先进个人、镜湖区优秀共产党员…",
        },
        {
          head: "https://downloads.whzhjy.cn/desktop/image/tch_head.png",
          name: "​耿昕蓉",
          school: "芜湖市第二十八中学",
          summary:
            "耿昕蓉，数学教师，一级教师，中共党员，镜湖区骨干教师、镜湖区师德先进个人、镜湖区优秀共产党员…",
        },
        {
          head: "https://downloads.whzhjy.cn/desktop/image/tch_head.png",
          name: "​耿昕蓉",
          school: "芜湖市第二十八中学",
          summary:
            "耿昕蓉，数学教师，一级教师，中共党员，镜湖区骨干教师、镜湖区师德先进个人、镜湖区优秀共产党员…",
        },
      ],
      swiper_one: null,
    };
  },
  computed: mapState({
    homePage_data: (state) => state.user.homePage_data,
    isLogin: (state) => state.user.isLogin,
  }),
  mounted() {
    this.yk_th = this.homePage_data.ykjs;
    try {
      this.ygykAllData = this.homePage_data.ygyk_new;
      this.ygykAllData.tbkc = JSON.parse(this.ygykAllData.tbkc).data;
      this.ygykAllData.ztkc = JSON.parse(this.ygykAllData.ztkc).data;
      this.ygykAllData.tbkc.forEach((item) => {
        item.publishTime = dateFormat("YYYY-mm-dd", item.publishTime);
      });
      this.ygykAllData.ztkc.forEach((item) => {
        item.publishTime = dateFormat("YYYY-mm-dd", item.publishTime);
      });
      this.ygyk_data = this.ygykAllData[this.dataAct];
      console.log(this.ygykAllData);
    } catch (e) {
      this.ygyk_data = [];
    }
    this.$nextTick(() => {
      this.initSwiper();
    });

    // this.ygyk_data.forEach((item) => {
    //   item.publishTime = dateFormat("YYYY-mm-dd", item.publishTime);
    // });
  },
  methods: {
    initSwiper() {
      this.swiper_one = new Swiper("#swiper_container_one", {
        slidesPerView: 4,
        spaceBetween: 24,
        observer: true,
        observeParents: true,
        // loop: true, // 循环模式选项 loop开启复制的元素不会同时复制dom绑定的事件 若要开启只能只用swiper的onClick回调函数
        loopAdditionalSlides: 0,
        autoplay: 3000,
        autoplayDisableOnInteraction: false,
        // 如果需要分页器
        prevButton: ".swiper-button-prev-one",
        nextButton: ".swiper-button-next-one",
      });
    },
    open(type, url) {
      if (type === 1) {
      } else {
        window.open(process.env.VUE_APP_PROJECT_URL + url);
      }
    },
    ygyk_open(id) {
      if (this.isLogin) {
        this.appLog("阳光云课");
        if (id) {
          checkUserRight(
            "ygyk",
            "阳光云课",
            "blank",
            `${
              window.location.host === "yanshi.whzhjy.cn"
                ? `https://ysygyk.whzhjy.cn/#/course-detail?id=${id}`
                : `https://ygyk.whzhjy.cn/#/course-detail?id=${id}`
            }`
          );
        } else {
          checkUserRight(
            "ygyk",
            "阳光云课",
            "blank",
            `${
              window.location.host === "yanshi.whzhjy.cn"
                ? "https://ysygyk.whzhjy.cn/#/course-list"
                : "https://ygyk.whzhjy.cn/#/course-list"
            }`
          );
        }
      } else {
        this.$message.warning("请登录后查看");
      }
    },
    cgDataType(type, item) {
      if (type === "yk") {
        console.log(this.ygykAllData);
        this.dataAct = item.val;
        this.ygyk_data = this.ygykAllData[this.dataAct];
      } else {
        this.jsDataAct = item.val;
        this.yk_th = this.homePage_data[item.val];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ygyk {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("https://downloads.whzhjy.cn/desktop/image/ygyk_bg.png") no-repeat top
    center;
  background-size: 100% 100%;
}
.ygyk_jyzx {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
}
.ygyk_cnt {
  width: 100%;
  height: 328px;
}
.ygyk_cnt_btm {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, #ffffff 100%);
  box-shadow: 0px 0px 18px 0px rgba(77, 100, 128, 0.12);
  border-radius: 0px 4px 4px 0px;
  .el-carousel {
    width: 440px;
  }
  .home_carousel_5 {
    ::v-deep .el-carousel__container {
      height: 246px;
    }
  }
  .ygyk_cnt_btm_left {
    position: relative;
    cursor: pointer;
    img {
      width: 440px;
      height: 246px;
    }
    div {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
      color: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: space-between;
      p {
        max-width: 250px;
        font-size: 14px;
      }
    }
  }
}
.ygyk_cnt_btm_right {
  width: calc(100% - 466px);
  padding: 10px 24px 10px 0;
  .ygyk_cnt_btm_right_item {
    display: flex;
    align-items: center;
    cursor: pointer;
    div:first-child {
      margin-right: 12px;
      text-align: center;
      img {
        width: 30px;
        height: 20px;
      }
      .last_pm {
        margin-left: 6px;
        margin-right: 4px;
        display: inline-block;
        width: 20px;
        height: 20px;
        font-size: 14px;
        background: #c8dbf4;
        border-radius: 50%;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 400;
        color: #595959;
        line-height: 20px;
        text-align: center;
      }
    }
    div:last-child {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8c8c8c;
      line-height: 38px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      p:first-child {
        max-width: 550px;
        color: #262626;
      }
    }
    &:hover {
      div:last-child {
        p:first-child {
          color: #368fff;
        }
      }
    }
  }
}
.ygyk_jsfc {
  width: 100%;
  padding: 20px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ygyk_jsfc_cnt {
  width: 1320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content_tit {
    width: 1200px;
  }
}
.jsfc_sw {
  width: 1320px;
  position: relative;
  padding-top: 24px;
  #swiper_container_one {
    width: 1200px;
  }
  .tch_card {
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    width: 282px;
    height: 160px;
    img {
      width: 100%;
      height: 100%;
    }
    .th_name {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 39px;
      background: rgba(0, 0, 0, 0.46);
      padding-left: 17px;
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      span {
        font-size: 12px;
        display: inline-block;
        margin-left: 10px;
        max-width: 200px;
      }
    }
    &:hover {
      .th_name {
        display: none;
      }
      .th_hv {
        top: 0;
      }
    }
  }
  .th_hv {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 160px;
    left: 0;
    padding: 28px 36px 0;
    color: #fff;
    background: linear-gradient(
      180deg,
      rgba(0, 84, 255, 0.7) 0%,
      rgba(53, 156, 255, 0.68) 100%
    );
    transition: all 0.3s;
    p:nth-of-type(1),
    p:nth-of-type(2) {
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      max-width: 209px;
    }
    p:nth-of-type(1) {
      font-size: 16px;
      padding-bottom: 12px;
    }
    p:nth-of-type(3) {
      padding-top: 11px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      max-height: 67px;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
.noData {
  width: 100%;
  height: 250px;
  background: url("https://downloads.whzhjy.cn/desktop/image/nodata.png") no-repeat center
      center,
    #fff;
  background-size: 322px 232px;
}
.swiper-button-prev-one,
.swiper-button-next-one {
  top: 58%;
}
</style>
