<template>
  <div class="all_application">
    <div class="application_area">
      <div class="yyzq">
        <div class="content_tit">
          <div>
            <img src="https://downloads.whzhjy.cn/desktop/image/yyzq_icon.png" alt="" />
            <div class="data_type">
              <div
                v-for="item in yyhdTypeList"
                :key="item.val"
                :class="yytpAct === item.val ? 'typeAct' : ''"
                @click="cgYytp(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <!-- <div
            class="more"
            @click="open(1,'/desktop/app-desktop/?id=1')"
          >
            <img src="https://downloads.whzhjy.cn/desktop/image/more.png" alt="" />
          </div> -->
        </div>
        <div class="yyzq_cnt">
          <div class="apl_list">
            <div v-if="yytpAct === 'zhgl'" class="app_cnt">
              <div
                v-for="(item, index) in zhglList"
                :key="index"
                @click="openAppUrl(item.url ? item.url : '', 'zhgl', item.title)"
              >
                <img :src="item.img" alt="" />
                <p>{{ item.title }}</p>
              </div>
            </div>
            <div v-if="yytpAct === 'tzjk'" class="app_cnt">
              <div
                v-for="(item, index) in tzjkList"
                :key="index"
                @click="open(1, '', item.data)"
              >
                <img :src="item.img" alt="" />
                <p>{{ item.title }}</p>
              </div>
            </div>
            <div v-if="yytpAct === 'ykt'">
              <el-carousel :autoplay="false" arrow="never" class="ykt_carousel">
                <el-carousel-item v-for="num in 3" :key="num">
                  <div class="app_cnt">
                    <div
                      v-for="(item, index) in yktList.slice((num - 1) * 12, num * 12)"
                      :key="index"
                      @click="openYkt(1, 'https://ecardv8.whzhjy.cn/dist/#/account')"
                    >
                      <img :src="item.img" alt="" />
                      <p>{{ item.title }}</p>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div v-if="yytpAct === 'ycsj'" class="app_ctx">
              <p>{{ yyContextList[0].title }}</p>
              <p>{{ yyContextList[0].cnt }}</p>
            </div>
            <div v-if="yytpAct === 'xgk'" class="app_cnt xgkList">
              <div
                v-for="(item, index) in xgkList"
                :key="index"
                @click="open(1, '', item.data, '', item.title)"
              >
                <img :src="item.img" alt="" />
                <p>{{ item.title }}</p>
              </div>
            </div>
            <div class="graphicsThr" v-if="yytpAct === 'ycsj'"></div>
            <div class="graphicsFou" v-if="yytpAct === 'ycsj'"></div>
          </div>
          <img
            class="yyzq_bg"
            v-if="yytpAct === 'zhgl'"
            src="https://downloads.whzhjy.cn/desktop/image/yyzq_bg1.png"
            alt=""
          />
          <img
            class="yyzq_bg"
            v-if="yytpAct === 'tzjk'"
            src="https://downloads.whzhjy.cn/desktop/image/yyzq_bg2.png"
            alt=""
          />
          <img
            class="yyzq_bg"
            v-if="yytpAct === 'ykt'"
            src="https://downloads.whzhjy.cn/desktop/image/yyzq_bg3.png"
            alt=""
          />
          <img
            class="yyzq_bg"
            v-if="yytpAct === 'ycsj'"
            src="https://downloads.whzhjy.cn/desktop/image/yyzq_bg4.png"
            alt=""
          />
          <img
            class="yyzq_bg"
            v-if="yytpAct === 'xgk'"
            src="https://downloads.whzhjy.cn/desktop/image/yyzq_bg5.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="xy_sx">
      <div class="xyjk">
        <div class="content_tit">
          <div>
            <img src="https://downloads.whzhjy.cn/desktop/image/study_lean.png" alt="" />
          </div>
          <div class="more"></div>
        </div>
        <div class="xyjk_list">
          <div
            v-for="(item, index) in xyjkList"
            :key="index"
            @click="open(1, '', item.data)"
          >
            <img :src="item.img" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="fwzx">
      <div class="content_tit">
        <div>
          <img src="https://downloads.whzhjy.cn/desktop/image/fwzx.png" alt="" />
        </div>
        <div
          class="more"
          @click="open(0, '/HelpCenter/main/default.action?id=7', '', 'noProUrl')"
        >
          <img src="https://downloads.whzhjy.cn/desktop/image/more.png" alt="" />
        </div>
      </div>
      <div class="fwzx_cnt">
        <div class="swiper-container" id="swiper_container_thr">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in fwzxList"
              :key="'0' + index"
            >
              <div class="fwzx_card" @click="open(0, item.link)">
                <p>{{ item.title }}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="swiper-button-prev swiper-button-prev-thr swiper-button-prev-b"
          slot="button-prev"
        >
          <i class="el-icon-arrow-left"></i>
        </div>
        <div
          class="swiper-button-next swiper-button-next-thr swiper-button-next-b"
          slot="button-next"
        >
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="system_reuse">
      <div class="content_tit">
        <div>
          <img src="../../assets/image/xtfy.png" alt="" />
        </div>
      </div>
      <div class="system_reuse_cnt">
        <span
          v-for="(item, index) in oldSystemList"
          :key="item.url"
          @click="openUrl(item.url)"
          >{{ item.name }}</span
        >
      </div>
    </div>
    <div class="yq_link">
      <div class="yq_cnt">
        <img
          class="yq_icon"
          src="../../assets/image/yq_link_icon.png"
          alt=""
          @click="openUrl('https://whzhjy.cn/openplatform/#/home')"
        />
        <div class="yq_link_list">
          <p>友情链接</p>
          <div>
            <img
              v-for="(item, index) in yqList"
              :key="index"
              :src="item.img"
              @click="openUrl(item.path)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dateFormat from "../../utils/dateFormat.js";
import { checkUserRight } from "../../utils/openPage.js";
import logMix from "../../mixin/index.js";
export default {
  name: "application_area",
  props: {},
  components: {},
  mixins: [logMix],
  data() {
    return {
      yytpAct: "zhgl",
      yyhdTypeList: [
        {
          name: "智慧管理",
          val: "zhgl",
        },
        {
          name: "体质健康",
          val: "tzjk",
        },
        {
          name: "校园一卡通",
          val: "ykt",
        },
        {
          name: "因材施教",
          val: "ycsj",
        },
        {
          name: "新高考",
          val: "xgk",
        },
      ],
      zhglList: [
        {
          img: "https://downloads.whzhjy.cn/desktop/image/xxfb.png",
          title: "信息发布",
          data: "",
          url:
            "https://portals.whzhjy.cn/client-lzysso/sso?from=ew&id=2eb268c33ee3481f946159a2194f155f&topNavbarTheme=black&level=cloud&ecsTopBar=true&ifAddPlatformSign",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/xl.png",
          title: "校历",
          data: "",
          url:
            "https://portals.whzhjy.cn/client-lzysso/sso?from=ew&id=da5a65e7770148fe8b84ab9342535c87&level=cloud&ecsTopBar=true&ifAddPlatformSign",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/rcxt.png",
          title: "日程协同",
          data: "",
          url:
            "https://portals.whzhjy.cn/client-lzysso/sso?from=ew&id=d5879e1883e94a0aacb3e5eb7970a1c3&topNavbarTheme=black&level=school&ecsTopBar=true",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/zcgl.png",
          title: "周程管理",
          data: "",
          url:
            "https://portals.whzhjy.cn/client-lzysso/sso?from=ew&id=f1c8c3a9d702455fa6ffd8a9f4798eef&level=school&ecsTopBar=true",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/wjdc.png",
          title: "问卷调查",
          data: "",
          url:
            "https://portals.whzhjy.cn/client-lzysso/sso?from=ew&id=ac8676d216d848b2b616e3a490df305c&level=cloud&ecsTopBar=true&ifAddPlatformSign",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/qjgl.png",
          title: "请假管理",
          data: "",
          url:
            "https://portals.whzhjy.cn/client-lzysso/sso?from=ew&id=17ae7c4a38cf4975926680037856da93&level=cloud&ecsTopBar=true&ifAddPlatformSign",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/znkq.png",
          title: "智能考勤",
          data: "",
          url:
            "https://portals.whzhjy.cn/client-lzysso/sso?from=ew&id=62e4909653e345dfb6872f25e299d58b&level=cloud&ecsTopBar=true&ifAddPlatformSign",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/zbpb.png",
          title: "值班排班",
          data: "",
          url:
            "https://portals.whzhjy.cn/client-lzysso/sso?from=ew&id=33feb89234384b88b5c7d337aed7ca6e&level=school&ecsTopBar=true",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/znpk.png",
          title: "智能排课",
          data: "",
          url:
            "http://www.lezhiyun.com/client-lzysso/sso?from=ew&id=ecd43e206dbf4e32a3b338f62a4c80e8&level=school&ecsTopBar=true&ifAddPlatformSign",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/zxxk.png",
          title: "在线选课",
          data: "",
          url:
            "https://www.lezhiyun.com/client-lzysso/sso?from=ew&id=404daad8f5ba4ed3b947e26741e0f0c6&level=school&ecsTopBar=true&ifAddPlatformSign",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/znkw.png",
          title: "智能考务",
          data: "",
          url: "https://www.lezhiyun.com/aikw/index.html",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/zxpj.png",
          title: "在线评教",
          data: "",
          url:
            "https://portals.whzhjy.cn/client-lzysso/sso?from=ew&id=9101773ec8294b6fa748dd782da98d70&level=school&ecsTopBar=true",
        },
      ],
      tzjkList: [
        {
          img: "https://downloads.whzhjy.cn/desktop/image/tj.png",
          title: "体检",
          data: [
            "tzjk",
            "",
            "",
            "https://whstc.whzhjy.cn/sso.html?referer=/physical/MechanismList.html",
          ],
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/pcgl.png",
          title: "普测管理",
          data: [
            "tzjk",
            "",
            "",
            "https://whstc.whzhjy.cn/sso.html?referer=/tpc/PTaskList.html",
          ],
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/gcgl.png",
          title: "国测管理",
          data: [
            "tzjk",
            "",
            "",
            "https://whstc.whzhjy.cn/sso.html?referer=/tgc/GTaskList.html",
          ],
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/ccgl.png",
          title: "抽测管理",
          data: [
            "tzjk",
            "",
            "",
            "https://whstc.whzhjy.cn/sso.html?referer=/tcc/CTaskList.html",
          ],
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/tyjt.png",
          title: "体育讲堂",
          data: [
            "tzjk",
            "",
            "",
            "https://whstc.whzhjy.cn/sso.html?referer=/forum/ForumList.html",
          ],
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/jcsjgl.png",
          title: "基础数据管理",
          data: [
            "tzjk",
            "",
            "",
            "https://whstc.whzhjy.cn/sso.html?referer=/sysmange/UserList.html",
          ],
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/zktyks.png",
          title: "中考体育考试",
          data: [
            "tzjk",
            "",
            "",
            "https://whstc.whzhjy.cn/sso.html?referer=/txyk/CZXTaskList.html",
          ],
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/gztyks.png",
          title: "高中体育考试",
          data: [
            "tzjk",
            "",
            "",
            "https://whstc.whzhjy.cn/sso.html?referer=/txyk/GZXTaskList.html",
          ],
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/tcbzll.png",
          title: "体测标准浏览",
          data: [
            "tzjk",
            "",
            "",
            "https://whstc.whzhjy.cn/sso.html?referer=/standard/StandardSearchList.html",
          ],
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/tjfx.png",
          title: "统计分析",
          data: [
            "tzjk",
            "",
            "",
            "https://whstc.whzhjy.cn/sso.html?referer=/static/TotelScore.html",
          ],
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/sljc.png",
          title: "视力监测",
          data: [
            "tzjk",
            "",
            "",
            "https://whstc.whzhjy.cn/sso.html?referer=/vision/VisionTaskList.html",
          ],
        },
      ],
      xyjkList: [
        {
          img: "https://downloads.whzhjy.cn/desktop/image/gzxypj.png",
          data: ["xyjk", "", "", "https://xypj.whzhjy.cn/"],
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/zhszpj.png",
          data: [
            "xyjk",
            "",
            "",
            "https://zhszpj.ahedu.cn/archives/common/gotoPageForRole",
          ],
        },
      ],
      yktList: [
        {
          img: require("../../assets/image/ykt-xtgl.png"),
          title: "系统管理",
        },
        {
          img: require("../../assets/image/ykt-jsgl.png"),
          title: "角色管理",
        },
        {
          img: require("../../assets/image/ykt-zhkz.png"),
          title: "账户控制",
        },
        {
          img: require("../../assets/image/ykt-czkgl.png"),
          title: "充值卡管理",
        },
        {
          img: require("../../assets/image/ykt-rzjl.png"),
          title: "日志记录",
        },
        {
          img: require("../../assets/image/ykt-xxwh.png"),
          title: "信息维护",
        },
        {
          img: require("../../assets/image/ykt-yhgl.png"),
          title: "用户管理",
        },
        {
          img: require("../../assets/image/ykt-bzgl.png"),
          title: "补助管理",
        },
        {
          img: require("../../assets/image/ykt-csgl.png"),
          title: "参数管理",
        },
        {
          img: require("../../assets/image/ykt-jkgl.png"),
          title: "集控管理",
        },
        {
          img: require("../../assets/image/ykt-qxpz.png"),
          title: "权限配置",
        },
        {
          img: require("../../assets/image/ykt-yywh.png"),
          title: "应用维护",
        },
        {
          img: require("../../assets/image/ykt-zdgl.png"),
          title: "终端管理",
        },
        {
          img: require("../../assets/image/ykt-dsfxtgl.png"),
          title: "第三方系统管理",
        },
        {
          img: require("../../assets/image/ykt-sbgl-2.png"),
          title: "设备管理",
        },
        {
          img: require("../../assets/image/ykt-qygl.png"),
          title: "区域管理",
        },
        {
          img: require("../../assets/image/ykt-sqgl.png"),
          title: "授权管理",
        },
        {
          img: require("../../assets/image/ykt-csgl-2.png"),
          title: "参数管理",
        },
        {
          img: require("../../assets/image/ykt-jcss.png"),
          title: "基础设施",
        },
        {
          img: require("../../assets/image/ykt-rzfw.png"),
          title: "认证服务",
        },
        {
          img: require("../../assets/image/ykt-ywxt.png"),
          title: "业务系统",
        },
        {
          img: require("../../assets/image/ykt-fwmh.png"),
          title: "服务门户",
        },
        {
          img: require("../../assets/image/ykt-glzx.png"),
          title: "管理中心",
        },
        {
          img: require("../../assets/image/ykt-rzgl.png"),
          title: "日志管理",
        },
        {
          img: require("../../assets/image/ykt-bbcx.png"),
          title: "报表查询",
        },
        {
          img: require("../../assets/image/ykt-khzx.png"),
          title: "客户中心",
        },
        {
          img: require("../../assets/image/ykt-kwgl.png"),
          title: "卡务管理",
        },
        {
          img: require("../../assets/image/ykt-xnkgl.png"),
          title: "虚拟卡管理",
        },
        {
          img: require("../../assets/image/ykt-yktsj.png"),
          title: "一卡通数据",
        },
        {
          img: require("../../assets/image/ykt-sbgl-2.png"),
          title: "设备管理",
        },
        {
          img: require("../../assets/image/ykt-yygl.png"),
          title: "应用管理",
        },
        {
          img: require("../../assets/image/ykt-jkzx.png"),
          title: "监控中心",
        },
      ],
      yyContextList: [
        {
          title: "因材施教",
          cnt:
            "运用大数据、知识图谱构建等智能技术，帮助教师减负增效，提升备、授课效率与课堂容量， 提高教研能力和信息化素养；以学生为中心，开展个性化学情分析和学习诊断，构建学生画像，同时根据学生的学习行为记录，帮助学生，做对题、少做题，实施精准教学，帮助学生规划最佳学习路径，缩短平均作业时长，实现人工智能支撑下的个性化学习，激发学习兴趣，培养更优秀的学生；帮助管理者及时掌握信息化系统建设与应用情况，实现基于大数据的科学化决策。",
        },
      ],
      xgkList: [
        {
          img: "https://downloads.whzhjy.cn/desktop/image/gzfzzd.png",
          title: "高中发展指导",
          data: ["xgk", "gzfzzd"],
          url:
            "http://www.lezhiyun.com/client-lzysso/sso?from=ew&id=a06cb677cfa4417eba37a08cda120d27&level=school&ecsTopBar=true",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/znpk.png",
          title: "智能排课",
          data: ["xgk", "znpk"],
          url:
            "http://www.lezhiyun.com/client-lzysso/sso?from=ew&id=ecd43e206dbf4e32a3b338f62a4c80e8&level=school&ecsTopBar=true&ifAddPlatformSign",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/zbkq.png",
          title: "走班考勤",
          data: ["xgk", "zbkq"],
          url:
            "http://www.lezhiyun.com/client-lzysso/sso?from=ew&id=844cf702de604463a4379956b93d1fca &level=school&ecsTopBar=true",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/gkxk.png",
          title: "高考选科",
          data: ["xgk", "gkxk"],
          url:
            "http://www.lezhiyun.com/client-lzysso/sso?from=ew&id=3492af6a9c6b415186854743d74633cd&level=cloud&ecsTopBar=true",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/sjcpt.png",
          title: "综合决策平台",
          data: ["xgkdsj", "szhjcpt"],
          url:
            "http://www.lezhiyun.com/client-lzysso/sso?ifAddPlatformSign&from=ew&forwardUrl=/app/enter.do?url=/open/sso/zhfw.do&ecsplatformid=1e27492f81ae431aac2fd3ff073cd8bc&",
        },
      ],
      fwzxList: [
        {
          title: "创建同步课程",
          link:
            "/manage/login_previewCourse.action?resType=video&fileName=创建同步课程&previewUrl=https://download.whzhjy.cn/web/help/video/tbkccj1.mp4&downloadUrl=https://download.whzhjy.cn/web/help/video/tbkccj1.mp4",
        },
        {
          title: "同步课程管理",
          link:
            "/manage/login_previewCourse.action?resType=video&fileName=%E5%90%8C%E6%AD%A5%E8%AF%BE%E7%A8%8B%E7%AE%A1%E7%90%86&previewUrl=https://download.whzhjy.cn/web/help/video/tbkcgl.mp4&downloadUrl=https://download.whzhjy.cn/web/help/video/tbkcgl.mp4",
        },
        {
          title: "我的备课",
          link:
            "/manage/login_previewCourse.action?resType=video&fileName=%E6%88%91%E7%9A%84%E5%A4%87%E8%AF%BE&previewUrl=https://download.whzhjy.cn/web/help/video/beike.mp4&downloadUrl=https://download.whzhjy.cn/web/help/video/beike.mp4",
        },
        {
          title: "我的授课",
          link:
            "/manage/login_previewCourse.action?resType=video&fileName=%E6%88%91%E7%9A%84%E6%8E%88%E8%AF%BE&previewUrl=https://download.whzhjy.cn/web/help/video/shouke.mp4&downloadUrl=https://download.whzhjy.cn/web/help/video/shouke.mp4",
        },
        {
          title: "我的资源库",
          link:
            "/manage/login_previewCourse.action?resType=video&fileName=%E6%88%91%E7%9A%84%E8%B5%84%E6%BA%90%E5%BA%93&previewUrl=https://download.whzhjy.cn/web/help/video/resource.mp4&downloadUrl=https://download.whzhjy.cn/web/help/video/resource.mp4",
        },
        {
          title: "教学反思",
          link:
            "/manage/login_previewCourse.action?resType=video&fileName=%E6%95%99%E5%AD%A6%E5%8F%8D%E6%80%9D&previewUrl=https://download.whzhjy.cn/web/help/video/jxfs.mp4&downloadUrl=https://download.whzhjy.cn/web/help/video/jxfs.mp4",
        },
        {
          title: "教学计划创建",
          link:
            "/manage/login_previewCourse.action?resType=video&fileName=%E6%95%99%E5%AD%A6%E8%AE%A1%E5%88%92%E5%88%9B%E5%BB%BA&previewUrl=https://download.whzhjy.cn/web/help/video/jhcj.mp4&downloadUrl=https://download.whzhjy.cn/web/help/video/jhcj.mp4",
        },
        {
          title: "计划总结",
          link:
            "/manage/login_previewCourse.action?resType=video&fileName=%E8%AE%A1%E5%88%92%E6%80%BB%E7%BB%93&previewUrl=https://download.whzhjy.cn/web/help/video/jhzj.mp4&downloadUrl=https://download.whzhjy.cn/web/help/video/jhzj.mp4",
        },
        {
          title: "辅导答疑",
          link:
            "/manage/login_previewCourse.action?resType=video&fileName=%E8%BE%85%E5%AF%BC%E7%AD%94%E7%96%91&previewUrl=https://download.whzhjy.cn/web/help/video/fddy.mp4&downloadUrl=https://download.whzhjy.cn/web/help/video/fddy.mp4",
        },
      ],
      yqList: [
        {
          img: "https://downloads.whzhjy.cn/desktop/image/gjzxx.png",
          path: "https://basic.smartedu.cn/",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/wjy_new.png",
          path: "http://www.ahedu.cn/#/",
        },
        {
          img: "https://downloads.whzhjy.cn/desktop/image/whjyj.png",
          path: "https://jyj.wuhu.gov.cn/",
        },
      ],
      oldSystemList: [
        {
          name: "备课系统",
          url: "https://www.ahedu.cn/SNS/index.php?app=Disk&mod=CloudBook&act=manageBook",
        },
        {
          name: "微课系统",
          url: "https://www.ahedu.cn/SNS/index.php?app=public&mod=Index&act=index",
        },
        {
          name: "教师专业发展系统",
          url: "http://ahfzcp.ahjygl.gov.cn/index.php?s=/admin/common/login.html",
        },
        {
          name: "中小学课程开设管理系统",
          url: "http://kecheng.ahjygl.gov.cn",
        },
        {
          name: "教育装备管理系统",
          url: "https://jyzb.ahjygl.gov.cn",
        },
        {
          name: "专题教育活动",
          url: "https://www.ahedu.cn/hdpx/#/home",
        },
        {
          name: "校园及学生安全管理信息系统",
          url: "https://xyaq.ahjygl.gov.cn/index.php/login/index.html",
        },
        {
          name: "中小学学籍管理系统",
          url: "https://xj.ahjygl.gov.cn/SMS.UI/Pages/Common/Login.aspx",
        },
        {
          name: "全国教师管理系统",
          url: "http://jiaoshi.ahedu.gov.cn",
        },
        {
          name: "全国教育信息化工作进展信息系统",
          url: "http://jyxxh.emis.edu.cn",
        },
        {
          name: "湾沚智慧教育",
          url: "https://wzqedu.net/desktop/home/#/",
        },
        {
          name: "弋江智慧教育",
          url: "https://www.yjzhjy.cn/#/",
        },
      ],
    };
  },
  computed: mapState({
    homePage_data: (state) => state.user.homePage_data,
    isLogin: (state) => state.user.isLogin,
    token: (state) => state.user.token,
    loginName: (state) => state.user.loginName,
    userId: (state) => state.user.userId,
  }),
  mounted() {
    // this.jyzx_data = this.homePage_data.yyzx
    this.$nextTick(() => {
      this.initSwiperThr();
    });
    // this.yqList = this.homePage_data.xglj;
  },
  methods: {
    cgYytp(item) {
      this.yytpAct = item.val;
    },
    open(type, url, data, proUrl, title) {
      if (type === 1) {
        if (
          data &&
          data[0] &&
          data[0] === "tzjk" &&
          data[3].indexOf("token") == -1 &&
          this.isLogin
        ) {
          data[3] = data[3] + `&token=${this.token}&userName=${this.loginName}`;
          // &userId=${this.userId}
        }
        if (!this.isLogin) {
          this.$message.warning("请登录后查看");
          return false;
        }
        if (url) {
          if (url === "https://ecardv8.whzhjy.cn/dist/#/account") {
            this.appLog("校园一卡通");
          }
          window.open(url);
          return false;
        }
        if (!data.length) {
          this.$message.warning("建设中...");
          return false;
        }
        // if(this.loginStatus){

        // }else{
        //   this.$message.info('请登录后查看')
        // }
        if (data[0] === "tzjk") {
          this.appLog("体质健康");
        } else if (data[0] === "xgk" || data[0] === "xgkdsj") {
          this.appLog(title);
        } else if (data[0] === "xyjk" && data[3] === "https://xypj.whzhjy.cn/") {
          this.appLog("学业评价");
        }
        checkUserRight(...data);
      } else {
        if (proUrl) {
          window.open(url);
          return false;
        } else {
          !url
            ? this.$message.warning("平台建设中...")
            : window.open(process.env.VUE_APP_PROJECT_URL + url);
        }
      }
    },
    openYkt() {
      if (!this.isLogin) {
        this.$message.warning("请登录后查看");
        return false;
      }
      this.$api.openYKT().then((res) => {
        if (res.data.data) {
          window.open(res.data.data);
        } else {
          this.$message.warning("暂无权限访问");
        }
      });
    },
    openAppUrl(url, type, title) {
      if (!this.isLogin) {
        this.$message.warning("请登录后查看");
        return false;
      }
      let userSchoolId = JSON.parse(sessionStorage.getItem("getNavbarConfigData")).user
        .schoolId;
      let haveRole = false;
      if (type == "zhgl") {
        zhglSchool.indexOf(userSchoolId) != -1 ? (haveRole = true) : "";
      } else if (type == "xgk") {
        xgkSchool.indexOf(userSchoolId) != -1 ? (haveRole = true) : "";
      }
      if (haveRole) {
        this.appLog(title);
        window.open(url);
      } else {
        this.$message.warning("您暂无访问权限");
      }
    },
    initSwiperThr() {
      this.swiper_thr = new Swiper("#swiper_container_thr", {
        slidesPerView: 4,
        spaceBetween: 24,
        observer: true,
        observeParents: true,
        // loop: true, // 循环模式选项 loop开启复制的元素不会同时复制dom绑定的事件 若要开启只能只用swiper的onClick回调函数
        loopAdditionalSlides: 0,
        autoplay: 3000,
        autoplayDisableOnInteraction: false,
        // 如果需要分页器
        prevButton: ".swiper-button-prev-thr",
        nextButton: ".swiper-button-next-thr",
      });
    },
    openUrl(url) {
      url ? window.open(url) : this.$message.error("链接不存在！");
    },
  },
};
</script>

<style scoped lang="scss">
.all_application,
.fwzx {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.application_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 0;
  height: 430px;
  background: url("https://downloads.whzhjy.cn/desktop/image/ygyk_bg.png") no-repeat
    bottom center;
}
.yyzq,
.xy_sx {
  width: 1200px;
}
.yyzq_cnt {
  display: flex;
  justify-content: space-between;
  min-height: 335px;
  .apl_list {
    position: relative;
    width: 721px;
    padding: 33px 0 0;
  }
  .yyzq_bg {
    position: relative;
    right: -10px;
    width: 447px;
    height: 337px;
  }
}
.graphicsThr {
  position: absolute;
  top: 70px;
  left: -30px;
  width: 81px;
  height: 81px;
  border: 20px solid #4b8eff;
  border-radius: 50%;
}
.graphicsFou {
  position: absolute;
  bottom: 50px;
  right: -25px;
  width: 74px;
  height: 74px;
  border: 16px solid #ffb44b;
  border-radius: 50%;
}
.app_cnt {
  display: flex;
  flex-wrap: wrap;
  div {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 16px 0px rgba(187, 202, 216, 0.49);
    border-radius: 4px;
    color: #262626;
    font-size: 14px;
    text-align: center;
    margin: 0 24px 34px 0;
    cursor: pointer;
    width: 100px;
    height: 118px;
    img {
      display: block;
      padding: 14px 20px 10px;
      width: 60px;
    }
    p {
      line-height: 14px;
      padding-bottom: 20px;
      position: relative;
    }
    &:nth-child(6n) {
      margin-right: 0;
    }
    &:hover {
      background: #fff;
      color: #368fff;
      p:after {
        content: "";
        position: absolute;
        bottom: 12px;
        left: 50%;
        transform: translateX(-50%);
        width: 24px;
        height: 3px;
        background: #368fff;
        border-radius: 3px;
      }
    }
  }
}
.app_ctx {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.59) 0%,
    rgba(255, 255, 255, 0.97) 100%
  );
  backdrop-filter: blur(10px);
  // background: rgba(255, 255, 255, 0.92);
  // background: #000;
  border: 2px solid #ffffff;
  padding: 23px 48px 35px;
  position: relative;
  z-index: 2;
  p:nth-of-type(1) {
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #262626;
    line-height: 37px;
    text-align: center;
    background: url("https://downloads.whzhjy.cn/desktop/image/yy_context_tit_bg.png")
      no-repeat center center;
  }
  p:nth-of-type(2) {
    padding-top: 12px;
    height: 152px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #595959;
    line-height: 24px;
  }
}
.xgkList {
  padding-top: 68px;
}
.xy_sx {
  padding: 32px 0 40px;
}
.xyjk_list {
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  div {
    margin-bottom: 16px;
    cursor: pointer;
    &:last-child {
      margin: 0;
    }
  }
  img {
    display: block;
    width: 580px;
    height: 136px;
  }
}
.sxgc_list {
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  img {
    display: block;
    cursor: pointer;
  }
}
.fwzx {
  background: url("https://downloads.whzhjy.cn/desktop/image/fwzx_bg.png") no-repeat top
    center;
  padding-top: 30px;
  background-size: 100% 100%;
  .swiper-button-next,
  .swiper-button-prev {
    top: 70%;
  }
  .content_tit {
    width: 1200px;
  }
  .fwzx_cnt {
    width: 1320px;
    position: relative;
    #swiper_container_thr {
      width: 1200px;
      padding-top: 23px;
      .swiper-slide {
        height: 147px;
        position: relative;
        display: flex;
        align-items: flex-end;
      }
    }
  }
}
.fwzx_card {
  width: 282px;
  height: 127px;
  cursor: pointer;
  background: linear-gradient(31deg, #d4e8ff 0%, #ffffff 100%);
  box-shadow: 0px 0px 16px 0px rgba(187, 202, 216, 0.49);
  border-radius: 0px 40px 0px 0px;
  transition: height 0.3s;
  position: relative;
  p {
    font-size: 24px;
    font-weight: 500;
    color: #262626;
    text-align: center;
    padding-top: 59px;
  }
  &:hover {
    height: 147px;
    background: linear-gradient(24deg, #bdd7ff 0%, #ffffff 100%);
    box-shadow: 0px 0px 16px 0px rgba(187, 202, 216, 0.49);
    border: 2px solid #368fff;
    P {
      color: #368fff;
      &::after {
        content: "";
        position: absolute;
        bottom: 48px;
        left: 50%;
        transform: translateX(-50%);
        width: 46px;
        height: 3px;
        background: #368fff;
        border-radius: 2px;
      }
    }
  }
}
.yq_link {
  width: 100%;
  background: url("https://downloads.whzhjy.cn/desktop/image/yq_link_bg.png") no-repeat
    top center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: 100% 100%;
  .yq_cnt {
    width: 1200px;
    display: flex;
    padding: 21px 6px 26px;
    align-items: center;
    .yq_icon {
      cursor: pointer;
      display: block;
      margin-right: 51px;
      width: 167px;
      height: 103px;
    }
  }
}
.yq_link_list {
  height: 86px;
  border-left: 1px solid rgba(255, 255, 255, 0.32);
  padding-left: 45px;
  // width: 900px;
  p {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 15px;
  }
  div {
    display: flex;
    img {
      margin-right: 16px;
      cursor: pointer;
      width: 286px;
      height: 53px;
    }
  }
}
.data_type div {
  width: 98px;
  padding: 9px 0;
  text-align: center;
  display: block;
}
.ykt_carousel {
  ::v-deep .el-carousel__indicators {
    bottom: -8px;
  }
  ::v-deep .el-carousel__indicator button {
    width: 20px;
    height: 6px;
    background: rgba(54, 143, 255, 0.4);
    border-radius: 3px;
    opacity: 1;
  }
  ::v-deep .el-carousel__indicator.is-active button {
    width: 55px;
    background: #368fff;
    border-radius: 3px;
  }
  ::v-deep .el-carousel__container {
    height: 304px;
  }
}
.system_reuse {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 37px 0 9px 0;
  background-image: url("../../assets/image/system_reuse_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .content_tit {
    width: 1200px;
    height: 29px;
  }
  .system_reuse_cnt {
    padding: 32px 0 0;
    width: 1200px;
    span {
      cursor: pointer;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      display: inline-block;
      padding: 0 48px 24px 0;
      opacity: 0.8;
      &:hover {
        // color: #368fff;
        opacity: 1;
      }
    }
  }
}
</style>
