<template>
  <div class="socurce_cnt">
    <div class="source_data">
      <div class="source_center">
        <div class="content_tit">
          <div>
            <img
              class="w20"
              src="https://downloads.whzhjy.cn/desktop/image/source_center_icon.png"
              alt=""
            />
            <div class="data_type">
              <div
                v-for="item in source_type"
                :key="item.type"
                @click="active_type = item.type"
                :class="active_type === item.type ? 'typeAct' : ''"
              >
                {{ item.tit }}
              </div>
            </div>
          </div>
          <div class="more" @click="goLink">
            <img src="https://downloads.whzhjy.cn/desktop/image/more.png" alt="" />
          </div>
        </div>
        <div class="home_cnt">
          <div v-for="item in [...pageData[active_type]]" :key="item.key">
            <div v-if="item.wy" class="wy">
              <div>五 育 资 源</div>
              <div>
                <img
                  v-for="imgItem in item.wy"
                  :key="imgItem.img"
                  :src="imgItem.img"
                  alt=""
                  @click="openWy(imgItem)"
                />
              </div>
            </div>
            <div v-if="item.xkzy" class="xkzy">
              <div>学 科 资 源</div>
              <div>
                <img
                  v-for="imgItem in item.xkzy"
                  :key="imgItem.img"
                  :src="imgItem.img"
                  alt=""
                  @click="openSubRes(imgItem)"
                />
              </div>
            </div>
            <div v-if="item.ztzy" class="ztzy">
              <div class="ztzy_tit">主 题 资 源</div>
              <div class="ztzy_cnt">
                <div
                  class="ztzy_item"
                  v-for="ztItem in item.ztzy"
                  :key="ztItem.img"
                  @click="openZtRes(ztItem)"
                >
                  <img :src="ztItem.icon" alt="" />
                  <div>
                    <p>{{ ztItem.title }}</p>
                    <p>{{ ztItem.content }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="yk_qt">
              <div class="yk">
                <div class="yk_qt_tit">阳 光 云 课</div>
                <div class="yk_item">
                  <span
                    v-for="ykItem in item.ygyk"
                    :key="ykItem.name"
                    @click="openYk(ykItem)"
                    >{{ ykItem.name }}</span
                  >
                </div>
              </div>
              <div class="qt">
                <div class="yk_qt_tit">其 他 资 源</div>
                <div
                  :class="['yk_item', 'qt_item', { qt_item_2: item.key == 'tchData' }]"
                >
                  <span
                    v-for="qtItem in item.qtzy"
                    :key="qtItem.name"
                    @click="openQt(qtItem)"
                    >{{ qtItem.name }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { goToResource } from "../../utils/openPage.js";
import { openApp } from "../../utils/goApp.js";
import logMix from "../../mixin/index.js";
export default {
  name: "source_data",
  props: {},
  components: {},
  mixins: [logMix],
  data() {
    return {
      source_type: [
        {
          type: 1,
          tit: "教师",
        },
        {
          type: 0,
          tit: "学生",
        },
        {
          type: 2,
          tit: "家长",
        },
      ],
      active_type: 1,
      pageData: [
        {
          key: "stuData",
          wy: [
            {
              img: "https://downloads.whzhjy.cn/desktop/image/dy.png",
              path: "",
              type: 1,
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/zy.png",
              path: "",
              type: 1,
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/ty.png",
              path: "",
              type: 1,
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/my.png",
              path: "",
              type: 1,
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/ldjy.png",
              path: "",
              type: 1,
            },
          ],
          ygyk: [
            {
              name: "语文",
              data: { type: 1, subject: "0001" },
            },
            {
              name: "数学",
              data: { type: 1, subject: "0002" },
            },
            {
              name: "英语",
              data: { type: 1, subject: "0003" },
            },
            {
              name: "物理",
              data: { type: 1, subject: "0005" },
            },
            {
              name: "化学",
              data: { type: 1, subject: "0006" },
            },
            {
              name: "生物",
              data: { type: 1, subject: "0013" },
            },
            {
              name: "历史",
              data: { type: 1, subject: "0012" },
            },
            {
              name: "地理",
              data: { type: 1, subject: "0014" },
            },
            {
              name: "音乐",
              data: { type: 1, subject: "0004" },
            },
            {
              name: "美术",
              data: { type: 1, subject: "0009" },
            },
            {
              name: "科学",
              data: { type: 1, subject: "0019" },
            },
            {
              name: "更多",
              data: { type: 1, subject: "" },
            },
          ],
          qtzy: [
            {
              name: "乐乐学堂",
              path: "",
            },
            {
              name: "十分钟课堂",
              path: "",
            },
          ],
        },
        {
          key: "tchData",
          wy: [
            {
              img: "https://downloads.whzhjy.cn/desktop/image/dy.png",
              path: "",
              type: 2,
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/zy.png",
              path: "",
              type: 2,
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/ty.png",
              path: "",
              type: 2,
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/my.png",
              path: "",
              type: 2,
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/ldjy.png",
              path: "",
              type: 2,
            },
          ],
          xkzy: [
            {
              img: "https://downloads.whzhjy.cn/desktop/image/yzy.png",
              code: "001",
              path: "",
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/syzy.png",
              code: "002",
              path: "",
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/ztzy.png",
              code: "003",
              path: "",
            },
          ],
          ygyk: [
            {
              name: "语文",
              data: { type: 2, subject: "0001" },
            },
            {
              name: "数学",
              data: { type: 2, subject: "0002" },
            },
            {
              name: "英语",
              data: { type: 2, subject: "0003" },
            },
            {
              name: "物理",
              data: { type: 2, subject: "0005" },
            },
            {
              name: "化学",
              data: { type: 2, subject: "0006" },
            },
            {
              name: "生物",
              data: { type: 2, subject: "0013" },
            },
            {
              name: "历史",
              data: { type: 2, subject: "0012" },
            },
            {
              name: "地理",
              data: { type: 2, subject: "0014" },
            },
            {
              name: "音乐",
              data: { type: 2, subject: "0004" },
            },
            {
              name: "美术",
              data: { type: 2, subject: "0009" },
            },
            {
              name: "科学",
              data: { type: 1, subject: "0019" },
            },
            {
              name: "更多",
              data: { type: 1, subject: "" },
            },
          ],
          qtzy: [
            // {
            //   name: "时代优课",
            //   path: "",
            // },
            // {
            //   name: "教学大师",
            //   path: "",
            // },
            // {
            //   name: "影视课堂",
            //   path: "",
            // },
            // {
            //   name:'云图书馆',
            //   path:''
            // },
            // {
            //   name:'博看电子期刊',
            //   path:''
            // },
            {
              name: "我的备课资源",
              path: "https://whzhjy.cn/school/repository_myResourceList.action",
            },
            {
              name: "电子教材",
              path: "https://whzhjy.cn/school/courseAndtestpaper_courseInfo.action",
            },
            {
              name: "复习检测试卷",
              path: "https://whzhjy.cn/school/courseAndtestpaper_testpaperInfo.action",
            },
            {
              name: "人大复印报刊",
              path: "",
            },
            {
              name: "中国知网",
              path: "",
            },
            {
              name: "十分钟课堂",
              path: "",
            },
            // ,{
            //   name:'百度文库',
            //   path:''
            // }
            // ,{
            //   name:'学科网',
            //   path:''
            // }
            {
              name: "乐乐学堂",
              path: "",
            },
          ],
        },
        {
          key: "parData",
          wy: [
            {
              img: "https://downloads.whzhjy.cn/desktop/image/dy.png",
              path: "",
              type: 3,
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/zy.png",
              path: "",
              type: 3,
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/ty.png",
              path: "",
              type: 3,
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/my.png",
              path: "",
              type: 3,
            },
            {
              img: "https://downloads.whzhjy.cn/desktop/image/ldjy.png",
              path: "",
              type: 3,
            },
          ],
          ztzy: [
            {
              icon: "https://downloads.whzhjy.cn/desktop/image/source_jz_sj.png",
              title: "“双减”教育",
              code: "CT1725",
              content: "通俗易懂的“双减”资源，服务“双减”教育，深化教育教学改革",
              data: { usertype: "parent", type: "01" },
            },
            {
              icon: "https://downloads.whzhjy.cn/desktop/image/source_jz_jj.png",
              title: "家教大讲堂",
              code: "CT1726",
              content: "家庭教育资源，助力家长言传身教，成为孩子一生中最好的老师",
              data: { usertype: "parent", type: "02" },
            },
            {
              icon: "https://downloads.whzhjy.cn/desktop/image/source_jz_jzkc.png",
              title: "家长课程",
              code: "CT2372",
              content: "创新多样的科普课程，和孩子一起了解世界，做孩子的“百科全书”",
              data: { usertype: "parent", type: "03" },
            },
          ],
          ygyk: [
            {
              name: "语文",
              data: { type: 3, subject: "0001" },
            },
            {
              name: "数学",
              data: { type: 3, subject: "0002" },
            },
            {
              name: "英语",
              data: { type: 3, subject: "0003" },
            },
            {
              name: "物理",
              data: { type: 3, subject: "0005" },
            },
            {
              name: "化学",
              data: { type: 3, subject: "0006" },
            },
            {
              name: "生物",
              data: { type: 3, subject: "0013" },
            },
            {
              name: "历史",
              data: { type: 3, subject: "0012" },
            },
            {
              name: "地理",
              data: { type: 3, subject: "0014" },
            },
            {
              name: "音乐",
              data: { type: 3, subject: "0004" },
            },
            {
              name: "美术",
              data: { type: 3, subject: "0009" },
            },
            {
              name: "科学",
              data: { type: 1, subject: "0019" },
            },
            {
              name: "更多",
              data: { type: 1, subject: "" },
            },
          ],
          qtzy: [
            {
              name: "乐乐学堂",
              path: "",
            },
            {
              name: "十分钟课堂",
              path: "",
            },
          ],
        },
      ],
    };
  },
  computed: mapState({
    token: (state) => state.user.token,
    isLogin: (state) => state.user.isLogin,
  }),
  mounted() {},
  methods: {
    goLink(type, data) {
      // goToResource(type, data);
      this.appLog("资源中心");
      window.open(
        window.location.origin +
          "/resource-center/front/#/layout/front/resource-home/index/whHome"
      );
    },
    openWy(item) {
      this.appLog("资源中心");
      item.type === 1
        ? window.open(
            window.location.origin +
              "/resource-center/front/#/layout/front/resource-home/index/whStuRes/whStuResWy"
          )
        : item.type === 2
        ? window.open(
            window.location.origin +
              "/resource-center/front/#/layout/front/resource-home/index/whThrRes/whThrResWy"
          )
        : window.open(
            window.location.origin +
              "/resource-center/front/#/layout/front/resource-home/index/whPatRes/whPatResWy"
          );
    },
    openYk(item) {
      this.appLog("资源中心");
      item.data.type === 1
        ? window.open(
            window.location.origin +
              `/resource-center/front/#/layout/front/resource-home/index/whStuRes/whStuResYgyk?subject=${item.data.subject}`
          )
        : item.data.type === 2
        ? window.open(
            window.location.origin +
              `/resource-center/front/#/layout/front/resource-home/index/whThrRes/whThrResYgyk?subject=${item.data.subject}`
          )
        : window.open(
            window.location.origin +
              `/resource-center/front/#/layout/front/resource-home/index/whPatRes/whPatResYgyk?subject=${item.data.subject}`
          );
    },
    openQt(item) {
      if (!this.isLogin) {
        this.$message.warning("请登录后查看");
        return false;
      }
      this.appLog("资源中心");
      if (item.path) {
        window.open(item.path);
      } else {
        openApp(item.name);
      }
    },
    openSubRes(item) {
      this.appLog("资源中心");
      window.open(
        window.location.origin +
          `/resource-center/front/#/layout/front/resource-home/index/whThrRes/whThrResSub?navCode=${item.code}`
      );
    },
    openZtRes(item) {
      this.appLog("资源中心");
      window.open(
        window.location.origin +
          `/resource-center/front/#/layout/front/resource-home/index/whPatRes/whPatResSub`
      );
    },
  },
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: "BigruixianBlackGBV10";
  src: url("../../assets/font/BigruixianBlackGBV10.TTF");
}
.socurce_cnt {
  width: 100%;
  background: url("https://downloads.whzhjy.cn/desktop/image/source_center_bg.png")
    no-repeat center;
  background-size: 100% 100%;
  padding: 32px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.source_data {
  width: 1200px;
}
.content_tit {
  position: relative;
}
.home_cnt {
  padding-top: 28px;
}
.home_tit {
  font-size: 34px;
  color: #262626;
  font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
  position: relative;
  font-weight: bold;
  margin: 32px 0 24px;
  span {
    font-size: 28px;
    color: #369fff;
    position: relative;
    z-index: 2;
  }
  i {
    width: 56px;
    height: 7px;
    background: #d4e7ff;
    position: absolute;
    left: 67px;
    bottom: 4px;
    z-index: 1;
  }
}
.wy,
.xkzy {
  margin-bottom: 24px;
  display: flex;
  div:first-child {
    width: 52px;
    height: 165px;
    background: linear-gradient(180deg, #509eff 0%, #9bc8ff 100%);
    border-radius: 4px 0px 0px 4px;
    font-size: 20px;
    font-family: BigruixianBlackGBV10;
    color: #ffffff;
    line-height: 26px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 32px 16px 0;
  }
  div:last-child {
    background: linear-gradient(332deg, #edf8ff 0%, #ffffff 100%);
    box-shadow: 0px 0px 18px 0px rgba(151, 165, 180, 0.2);
    border-radius: 0 4px 4px 0;
    display: flex;
    flex: 1 1 auto;
    padding: 24px;
    justify-content: space-between;
    img {
      cursor: pointer;
      width: 207px;
      height: 117px;
      &:hover {
        box-shadow: 0px 0px 18px 0px rgba(151, 165, 180, 0.2);
      }
    }
  }
}
.xkzy {
  div:first-child {
    background: linear-gradient(180deg, #52c1ff 0%, #99e9e0 100%);
  }
  div:last-child {
    img {
      width: 350px;
    }
  }
}
.ztzy {
  background: linear-gradient(332deg, #edf8ff 0%, #ffffff 100%);
  box-shadow: 0px 0px 18px 0px rgba(151, 165, 180, 0.2);
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  .ztzy_tit {
    width: 52px;
    height: 165px;
    background: linear-gradient(180deg, #ffb24b 0%, #ffd193 100%);
    border-radius: 4px 0px 0px 4px;
    font-size: 20px;
    font-family: BigruixianBlackGBV10;
    color: #ffffff;
    line-height: 26px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 32px 16px 0;
  }
  .ztzy_cnt {
    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;
    padding: 22px 24px;
  }
  .ztzy_item {
    display: flex;
    cursor: pointer;
    position: relative;
    width: 343px;
    padding-right: 40px;
    img {
      display: block;
      width: 120px;
      height: 120px;
      margin-right: 20px;
    }
    div {
      font-size: 18px;
      color: #262626;
      line-height: 22px;
      padding-top: 10px;
      p:nth-of-type(1) {
        margin: 0;
      }
      p:nth-of-type(2) {
        font-size: 14px;
        color: #8c8c8c;
        width: 180px;
        margin: 10px 0 0;
      }
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 10px;
      height: 100px;
      width: 1px;
      background: #dfdbdb;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    &:hover {
      div {
        p:nth-of-type(1) {
          color: #369fff;
        }
      }
    }
  }
}
.yk_qt {
  display: flex;
  justify-content: space-between;
  .yk,
  .qt {
    width: 588px;
    height: 188px;
    background: linear-gradient(332deg, #fff9ef 0%, #ffffff 100%);
    box-shadow: 0px 0px 18px 0px rgba(151, 165, 180, 0.2);
    border-radius: 4px;
    overflow: hidden;
    .yk_qt_tit {
      display: inline-block;
      width: 52px;
      height: 188px;
      background: linear-gradient(180deg, #fca1a0 0%, #f6cfad 100%);
      font-size: 20px;
      font-family: BigruixianBlackGBV10;
      color: #ffffff;
      line-height: 26px;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      padding: 42px 16px 0;
    }
  }
  .qt {
    background: linear-gradient(332deg, #f9f6ff 0%, #ffffff 100%);
    .yk_qt_tit {
      background: linear-gradient(180deg, #9c9aff 0%, #c1a7ff 100%);
    }
  }
}
.yk_item {
  display: inline-block;
  width: calc(100% - 52px);
  padding: 40px 106px 28px;
  font-size: 14px;
  color: #262626;
  vertical-align: bottom;
  span {
    cursor: pointer;
    display: inline-block;
    line-height: 14px;
    padding: 7px 15px;
    border-radius: 20px;
    background: #fff6f6;
    border: 1px solid #ffdfdf;
    margin: 0 20px 10px 0;
    &:hover {
      background: #eebebe;
      color: #fff;
    }
  }
}
.qt_item {
  padding: 74px 99px;
  span {
    background: #f8f8ff;
    border-color: #dad8ff;
    &:hover {
      background: #d0d0f7;
      color: #fff;
    }
  }
}
.qt_item_2 {
  padding: 30px 36px;
}
</style>
