export default {
  data() {
    return {

    }
  },
  methods: {
    appLog(name) {
      this.$api.getAppId(name)
      .then((res) => {
        try{
          if(res){
            console.log(res.data,{storeAppId:res.data})
            this.bigDataLogIn({storeAppId:res.data})
          }
        }catch(err){
          console.log(err,'埋点获取应用key值错误')
        }
      })
      .catch((err) => {
        console.log(err)
      })
    },
    bigDataLogIn(app) {
      this.$api.getIflyCollectorConfig().then((data) => {
        let platformNo = data.platformId;
        let extParams = {
          // 平台标识，根据进入系统的参数来判断，如果参数中有platformNo用参数中的，没有的话默认iflytek
          platformNo: platformNo ? platformNo : "iflytek",
          appId: app.storeAppId, // 应用id
          thirdParty: false, // 保持不变
        };
        console.log(data.userId, data.eventId, data.moduleId, extParams,'埋点传参')
        // 执行埋点
        window.bigdatalog(data.userId, data.eventId, data.moduleId, extParams, null);
      });
    },
  }
}