import md5 from './md5.min'
export function rucdm_login(openType) {
  var ci = "pjmijhl%05%05";
  var cn = "%E8%81%9A%E6%B0%91%E5%AE%B9%E7%8B%AB%E5%83%8D%E6%8C%8B%E7%B9%AB%E9%9D%94";
  var date = new Date();
  var rr = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  var ss = md5(ci + md5(ci).substring(7, 15) + rr);
  var formid = "loginRucdmForm";
  var form = document.getElementById(formid);
  if (form) {
    form.submit()
  } else {
    var formString = "";
    if (openType == 'cur') {
      formString = "<form id='" + formid + "' action='https://www.rdfybk.com/sso/login' method='post'>";
    } else {
      formString = "<form id='" + formid + "' action='https://www.rdfybk.com/sso/login' method='post' target='_blank'>";
    }
    formString += "<input id=\"rucdm_a\" name=\"rucdm_a\" type=\"hidden\"  value=\"" + cn + "\"/>";
    formString += "<input id=\"rucdm_b\" name=\"rucdm_b\" type=\"hidden\" value=\"" + rr + "\" />";
    formString += "<input id=\"rucdm_c\" name=\"rucdm_c\" type=\"hidden\" value=\"" + ci + "\" />";
    formString += "<input id=\"rucdm_d\" name=\"rucdm_d\" type=\"hidden\" value=\"" + ss + "\" />";
    formString += "</form>";
    document.body.innerHTML += formString;
    form = document.getElementById(formid);
    form.submit()
  }
}
