import {
  Message
} from 'element-ui';
import jq from 'jquery'
// let rootPath = window.location.origin
let rootPath = process.env.NODE_ENV === 'development' ? 'https://wuhutestbasic.changyan.cn/qxpt_third' : 'https://whzhjy.cn'
let rootPath1 = process.env.NODE_ENV === 'development' ? 'https://wuhutestbasic.changyan.cn' : 'https://whzhjy.cn'
export function checkUserRight(type, app, openType, openUrl) {
  window.currentApp = {}
  window.currentApp.type = type;
  window.currentApp.app = app;
  window.currentApp.openType = openType;
  window.currentApp.openUrl = openUrl;
  let showTip = false
  if (type == 'xyjk' || type == 'yzzy') {
    openNewTab(openUrl);
    return true;
  } else if (type == 'ygyk') {
    openNewTab(openUrl);
    return true;
  } else if (type == 'zmyy') {
    jq.ajax({
      url: rootPath1 + "/desktop/backend/indexPage/checkAppRight",
      data: {
        "appId": app,
      },
      type: "GET",
      async: false,
      success: function (data) {
        if (data.data) {
          openNewTab(openUrl)
        } else {
          // Message({message:'暂无权限',type:'warning'})
          showTip = true
        }
      },
      error: function () {}
    });
    if (showTip) {
      return "showTip"
    }
  } else {
    jq.ajax({
      url: rootPath + "/manage/home_checkUserAppRight.action?" + Math.random(),
      data: {
        "type": type,
        'app': app
      },
      type: "GET",
      async: false,
      success: function (data) {
        if (type == 'tzjk') {
          if (data.code == '203') {
            // window.location.href = "http://whtcbeta.whsedu.cn/student/UserInfo.html";
            openNewTab("http://whtcbeta.whsedu.cn/student/UserInfo.html")
            return true;
          } else {
            // window.location.href = openUrl || window.currentApp.openUrl;
            openNewTab(openUrl)
            return true;
          }
        } else if (data.code == '200') {
          var url = data.url;
          if (url.indexOf("http") == -1) {
            url = rootPath + url;
          }
          // if (window.currentApp.openType == 'blank') {
          openNewTab(url);
          // } else {
            // window.location.href = url;
          // }

        } else {
          Message({
            message: '您暂无访问权限',
            type: 'warning'
          })
        }
      },
      error: function () {}
    });
  }
}

function openNewTab(url) {
  window.open(url)
}

export function goToResource(module, object = {}) {
  let RESOURCE_BASE_URL = 'https://whzhjy.cn'
  let {
    usertype,
    subject,
    wuyu,
    app,
    phase,
    type
  } = object
  let path = ''
  if (module === "ygyk") {
    // 阳光云课
    path = "/zyzxres/wyIndex/ygykIndex.action?from=ew&appId=200399472e0042c2a0266e08aef303101e27492f81ae431aac2fd3ff073cd8bc";
    path = path + "&usertype=" + usertype + "&subject=" + subject;

  } else if (module === "wuyu") {
    // 五育资源
    path = "/zyzxres/wyIndex/platform.action?from=ew&appId=200399472e0042c2a0266e08aef303101e27492f81ae431aac2fd3ff073cd8bc";
    path = path + "&usertype=" + usertype + "&resType=" + wuyu;

  } else if (module === "featureres") {
    // 其他资源
    path = "/zyzxres/wyIndex/featureres.action?from=ew&appId=200399472e0042c2a0266e08aef303101e27492f81ae431aac2fd3ff073cd8bc";
    path = path + "&usertype=" + usertype + "&app=" + app;

  } else if (module === "resource") {
    // 同步资源
    path = "/zyzxres/wyIndex/resourceIndex.action?from=ew&appId=200399472e0042c2a0266e08aef303101e27492f81ae431aac2fd3ff073cd8bc&tabCode=01";
    path = path + "&usertype=" + usertype + "&phaseCd=" + phase + "&subjectCd=" + subject;

  } else if (module === "parent") {
    // 家长课程
    path = `/zyzxres/wyIndex/parentPlatForm.action?type=${type}&from=ew&appId=200399472e0042c2a0266e08aef303101e27492f81ae431aac2fd3ff073cd8bc`;
    path = path + "&usertype=" + usertype;
  } else {
    // 直接跳转资源中心
    path = "/zyzxres";

  }
  window.open(RESOURCE_BASE_URL + path);
}