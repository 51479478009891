<template>
  <div class="home">
    <div class="banner">
      <el-carousel
        class="home_carousel_1"
        :arrow="bannerList.length > 1 ? 'hover' : 'never'"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img :src="item.img" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content_1">
      <div class="application">
        <el-carousel
          class="home_carousel_2"
          :autoplay="false"
          :arrow="applicationList.length > 5 ? 'always' : 'never'"
          :loop="false"
          indicator-position="none"
        >
          <el-carousel-item
            v-for="item in Math.ceil(applicationList.length / 5)"
            :key="item"
            class="application_type_cnt"
          >
            <div
              v-for="(items, index) in applicationList.slice(
                item > 1 ? 5 * (item - 1) : 0,
                5 * item
              )"
              :key="index"
              class="application_type"
              @mouseover="cgAppType(items, index)"
              :class="showMdIndex === index ? 'actItemBt' : ''"
            >
              <div
                :class="[
                  'ap_type' + index,
                  showMdIndex === index ? 'ap_type_act' + index : '',
                ]"
              ></div>
              <p :title="items.name" :class="showMdIndex === index ? 'actItem' : ''">
                {{ items.name }}
              </p>
            </div>
          </el-carousel-item>
        </el-carousel>
        <el-carousel
          class="home_carousel_3"
          ref="app_carousel"
          :autoplay="false"
          :arrow="selectApplicationType.length > 8 ? 'always' : 'never'"
        >
          <el-carousel-item
            v-for="item in Math.ceil(selectApplicationType.length / 8)"
            :key="item"
          >
            <div class="application_item">
              <div
                v-for="(items, index) in selectApplicationType.slice(
                  item > 1 ? 8 * (item - 1) : 0,
                  8 * item
                )"
                :key="index"
                @click="open(1, '', items)"
              >
                <img :src="items.icon" alt="" />
                <p class="overText" :title="items.name">{{ items.name }}</p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="notice">
        <div class="sc_news">
          <div class="content_tit">
            <div>
              <img src="https://downloads.whzhjy.cn/desktop/image/xxzx_icon.png" alt="" />
              <div class="data_type">
                <div
                  v-for="item in xwzxTypeList"
                  :key="item.val"
                  :class="xwxzAct === item.val ? 'typeAct' : ''"
                  @click="cgXwzx(item)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="more" @click="openMoreNews">
              <img src="https://downloads.whzhjy.cn/desktop/image/more.png" alt="" />
            </div>
          </div>
          <div class="sc_news_cnt" v-if="newsDemoList.length > 0">
            <div class="sc_news_cnt_left">
              <el-carousel class="home_carousel_4">
                <el-carousel-item v-for="item in newsDemoList.slice(0, 3)" :key="item.id">
                  <div class="news_item" @click="openNews(item)">
                    <img
                      :src="
                        item.img
                          ? item.img
                          : 'https://downloads.whzhjy.cn/desktop/image/mrt.png'
                      "
                      alt=""
                    />
                    <div>
                      <p class="overText" :title="item.title">{{ item.title }}</p>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="sc_news_cnt_rit" v-if="newsDemoList.length > 3">
              <div @click="openNews(newsDemoList[3])">
                <p class="overText" :title="newsDemoList[3].title">
                  {{ newsDemoList[3].title }}
                </p>
                <p>{{ newsDemoList[3].description }}</p>
              </div>
              <div
                v-for="(item, index) in newsDemoList.slice(4, 9)"
                :key="index"
                class="news_rit_item"
                @click="openNews(item)"
              >
                <p class="overText" :title="item.title">
                  <i class="el-icon-d-arrow-right"></i>
                  {{ item.title }}
                </p>
                <p>{{ item.createTime && item.createTime.slice(0, 10) }}</p>
              </div>
            </div>
          </div>
          <div class="noData" v-else></div>
        </div>
        <div class="bulletin">
          <div class="content_tit">
            <div>
              <img src="https://downloads.whzhjy.cn/desktop/image/tzgg_icon.png" alt="" />
            </div>
            <div
              class="more"
              @click="open(0, 'https://jyj.wuhu.gov.cn/xwzx/tzgg/index.html', '')"
            >
              <img src="https://downloads.whzhjy.cn/desktop/image/more.png" alt="" />
            </div>
          </div>
          <div class="bulletin_cnt">
            <div
              v-for="(item, index) in tzggList.slice(0, 4)"
              :key="index"
              class="tzgg_item"
              @click="openNews(item)"
            >
              <div>
                <p>{{ item.createTime && item.createTime.slice(8, 10) }}</p>
                <p>{{ item.createTime && item.createTime.slice(0, 7) }}</p>
              </div>
              <div :title="item.title">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="getDataNow" class="content_2">
      <source_data></source_data>
      <teachingCenter></teachingCenter>
      <dataCenter></dataCenter>
      <smartClassroom></smartClassroom>
      <ygyk></ygyk>
      <school_overview></school_overview>
      <applicationArea></applicationArea>
      <link_footer></link_footer>
    </div>
    <div class="bayWindow" ref="bayWin" v-show="isShowBay">
      <img
        v-for="(item, index) in bayWindowList"
        :key="index"
        :src="item.icon"
        alt=""
        @click="bayWindowOpen(item)"
      />
      <i class="el-icon-error" v-if="bayWindowList.length > 0" @click="cancelBay"></i>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="384px"
      :show-close="false"
      :append-to-body="true"
    >
      <div>
        <p>您好，您当前使用的角色没有权限，请使用以下角色：</p>
        <p>{{ tipsMsg }}</p>
        <p>
          <i class="el-icon-warning-outline"></i>
          小提示：如果您在平台拥有多个角色，请在平台导航右上角进行切换。
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="tips_btn" type="primary" @click="dialogVisible = false"
          >我知道了</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import source_data from "./home_com/source_data.vue";
import ygyk from "./home_com/ygyk.vue";
import school_overview from "./home_com/school_overview";
import link_footer from "./home_com/footer.vue";
import dateFormat from "../utils/dateFormat.js";
import { checkUserRight } from "../utils/openPage.js";
// import bayWindow from '../utils/bayWindow.js';
// import bulletin from "../components/bulletin.vue";
import teachingCenter from "./home_com/teaching_center.vue";
import dataCenter from "./home_com/data_center.vue";
import smartClassroom from "./home_com/smart-classroom.vue";
import applicationArea from "./home_com/application_area.vue";
import logMix from "../mixin/index.js";
export default {
  name: "home",
  props: {},
  mixins: [logMix],
  components: {
    source_data,
    ygyk,
    school_overview,
    link_footer,
    // bulletin,
    teachingCenter,
    dataCenter,
    smartClassroom,
    applicationArea,
  },
  data() {
    return {
      dialogVisible: false,
      tipsMsg: "",
      bannerList: [
        {
          img: require("../assets/image/banner1.png"),
          // "https://downloads.whzhjy.cn/desktop/image/banner1.png",
        },
      ],
      applicationList: [],
      selectApplicationType: [],
      newsDemoList: [],
      tzggList: [],
      bayWindowList: [],
      isShowBay: true,
      showMdIndex: 0,
      getDataNow: false,
      xwzxTypeList: [
        {
          name: "部门要闻",
          val: "bm",
        },
        {
          name: "行业动态",
          val: "hy",
        },
        {
          name: "学校资讯",
          val: "xx",
        },
      ],
      xwxzAct: "bm",
      jyzxList: [],
      xxzxList: [],
      bmywList: [],
      hydtList: [],
    };
  },
  computed: mapState({
    isLogin: (state) => state.user.isLogin,
    token: (state) => state.user.token,
    loginName: (state) => state.user.loginName,
    userId: (state) => state.user.userId,
  }),
  mounted() {
    this.getAllModuleNames();
    this.getIndexAllData();
    this.getShowWaveWindowList();
    // this.getJyzx()
    // this.getBmyw();
    this.getHydt();
  },
  methods: {
    cgXwzx(item) {
      //新闻资讯类型
      if (item.val == "xx") {
        this.newsDemoList = this.xxzxList;
      } else if (item.val == "bm") {
        this.newsDemoList = this.bmywList;
      } else if (item.val == "hy") {
        this.newsDemoList = this.hydtList;
      }
      this.xwxzAct = item.val;
    },
    cgAppType(data, index) {
      this.showMdIndex = index;
      if (data) {
        this.selectApplicationType = data.applicationNameVos;
        this.$refs.app_carousel.setActiveItem(0);
      }
    },
    // 获取飘窗
    getShowWaveWindowList() {
      this.$api
        .getShowWaveWindowList()
        .then((res) => {
          if (res.flag && res.data.length > 0) {
            this.bayWindowList = res.data;
            // this.leaveWin();
            this.setBayWin();
          }
        })
        .catch((err) => {
          this.$message.error("飘窗返回数据错误");
        });
    },
    // 修改宣传位定位
    setBayWin() {
      let width = document.body.clientWidth - 1200 - 168 * 2;
      this.$refs.bayWin.style.left = width / 2 > 168 ? width / 2 - 36 + "px" : 0;
    },
    // 获取应用模块
    getAllModuleNames() {
      this.$api
        .getAllModuleNames()
        .then((res) => {
          if (res.flag && res.data.length > 0) {
            this.applicationList = res.data;
            this.selectApplicationType = this.applicationList[0].applicationNameVos;
          }
        })
        .catch((err) => {
          this.$message.error("应用推荐接口返回数据错误");
        });
    },
    getIndexAllData() {
      this.$api.getIndexAllData().then((res) => {
        if (res.data) {
          this.$store.commit("setHomePage_data", res.data);
          this.getDataNow = true;
          // if (res.data.xxzx.length > 0) {
          this.xxzxList = [...res.data.xxzx1, ...res.data.xxzx2];
          // this.xxzxList = res.data.xxzx
          this.xxzxList.forEach((item) => {
            item.createTime = dateFormat("YYYY-mm-dd", item.createTime);
            item.img = item.thumb;
          });
          // this.newsDemoList = this.xxzxList;
          // }
          // this.tzggList = res.data.tzgg;
        }
      });
    },
    open(type, url, data, have) {
      if (type === 1) {
        if (data.type === "1") {
          if (!this.isLogin) {
            this.$message.warning("请登录后查看");
            return false;
          } else {
            if ((url && url.indexOf("http") != 0) || data.url.indexOf("http") != 0) {
              this.$message.warning(data.tips || data.url || url);
              return false;
            }
            let msg = checkUserRight("zmyy", data.storeAppId, "", data.url);
            if (msg === "showTip") {
              if (data.tips) {
                this.tipsMsg = data.tips;
                this.dialogVisible = true;
              } else {
                this.$message.warning("暂无权限...");
              }
            }
            this.bigDataLogIn(data);
            return false;
          }
        } else {
          if ((url && url.indexOf("http") != 0) || data.url.indexOf("http") != 0) {
            this.$message.warning(data.tips || data.url || url);
            return false;
          }
          if (data.name == "体质健康") {
            window.open(data.url + `&token=${this.token}&userName=${this.loginName}`);
            this.bigDataLogIn(data);
          } else {
            if (data.url === "建设中") {
              this.$message.warning("正在建设中...");
              return false;
            }
            window.open(data.url);
          }
        }
      } else {
        if (have) {
          window.open(process.env.VUE_APP_PROJECT_URL + url);
          return false;
        }
        window.open(url);
      }
    },
    bayWindowOpen(item) {
      if (item.loginTag === "0") {
        window.open(item.url);
      } else {
        if (!this.isLogin) {
          this.$message.warning("请登录后查看");
          return false;
        } else {
          window.open(item.url);
        }
      }
    },
    cancelBay() {
      this.isShowBay = false;
    },
    openMoreNews() {
      if (this.xwxzAct === "xx") {
        this.appLog("学校风采");
        window.open("/school-news/news/lastedNews.action");
      } else if (this.xwxzAct === "hy") {
        window.open("https://jyj.wuhu.gov.cn/xwzx/xydt/index.html");
      } else {
        window.open("https://jyj.wuhu.gov.cn/xwzx/bmyw/index.html");
      }
    },
    openNews(item) {
      if (this.xwxzAct === "xx") {
        this.appLog("学校风采");
        window.open(item.newsUrl);
      } else {
        window.open(item.link);
      }
    },
    // getJyzx(){
    //   this.$api.getJyzx().then(res=>{
    //     if(res.data.data){
    //       this.jyzxList = res.data.data.datalist
    //       this.jyzxList.forEach(item=>{
    //         item.description = item.contentText
    //         item.img = item.coverUrl
    //         item.createTime = dateFormat('YYYY-mm-dd', item.createTime);
    //       })
    //       this.newsDemoList = this.jyzxList
    //     }
    //   })
    // }
    // getBmyw() {
    //   this.$api.getBmyw().then((res) => {
    //     if (res.data.data) {
    //       this.bmywList = res.data.data.datalist;
    //       this.bmywList.forEach((item) => {
    //         item.description = item.desc;
    //         item.img = item.imgUrl;
    //         item.createTime = item.createTimeFormat;
    //       });
    //       this.newsDemoList = this.bmywList;
    //     }
    //   });
    // },
    getHydt() {
      this.$api.getZfxwNews().then((res) => {
        if (res.data) {
          this.hydtList = res.data.hydt;
          this.hydtList.forEach((item) => {
            item.description = item.remarks;
            item.img = item.imageLink;
            item.createTime = item.createDate;
          });
          this.bmywList = res.data.bmyw;
          this.bmywList.forEach((item) => {
            item.description = item.remarks;
            item.img = item.imageLink;
            item.createTime = item.createDate;
          });
          this.tzggList = res.data.tzgg || [];
          this.tzggList.forEach((item) => {
            item.createTime = item.createDate;
          });
          this.newsDemoList = this.bmywList;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.home,
.content_2 {
  font-size: 12px;
  width: 100%;
  min-width: 1400px;
  height: 100%;
  background: rgba(253, 254, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.banner {
  width: 100%;
  ::v-deep .el-carousel__item {
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      // width: 100%;
      height: 100%;
    }
  }
}
.home_carousel_1 {
  ::v-deep .el-carousel__container {
    height: 460px;
  }
}
.home_carousel_2 {
  ::v-deep .el-carousel__container {
    height: 72px;
  }
}
.home_carousel_3 {
  ::v-deep .el-carousel__container {
    height: 167px;
  }
}
.home_carousel_4 {
  ::v-deep .el-carousel__container {
    height: 284px;
  }
}
.content_1 {
  width: 1200px;
  margin-top: -62px;
  z-index: 2;
}
.application_type_cnt {
  padding: 4px 165px 0;
  display: flex;
  justify-content: space-between;
}
.application {
  background: linear-gradient(180deg, #f0f4ff 0%, #ffffff 100%);
  box-shadow: 0px 0px 18px 0px rgba(77, 100, 128, 0.12);
  .el-carousel:first-child {
    border-bottom: 1px solid #d9dce2;
  }
  .el-carousel:nth-of-type(2) {
    ::v-deep .el-carousel__indicators {
      display: none;
    }
  }
  .el-carousel__item {
    overflow: hidden;
  }
  ::v-deep .el-carousel__arrow {
    width: 24px;
    height: 24px;
    background: #f1f3f6;
    color: #368fff;
    font-size: 14px;
    i {
      font-weight: bold;
    }
  }
  .application_type {
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 3px transparent solid;
    div {
      width: 28px;
      height: 28px;
      margin-right: 8px;
      background-size: 100% !important;
      &.ap_type0 {
        background: url("https://downloads.whzhjy.cn/desktop/image/ap_type0.png")
          no-repeat center;
      }
      &.ap_type1 {
        background: url("https://downloads.whzhjy.cn/desktop/image/ap_type1.png")
          no-repeat center;
      }
      &.ap_type2 {
        background: url("https://downloads.whzhjy.cn/desktop/image/ap_type2.png")
          no-repeat center;
      }
      &.ap_type3 {
        background: url("https://downloads.whzhjy.cn/desktop/image/ap_type3.png")
          no-repeat center;
      }
      &.ap_type4 {
        background: url("https://downloads.whzhjy.cn/desktop/image/ap_type4.png")
          no-repeat center;
      }
      &.ap_type_act0 {
        background: url("https://downloads.whzhjy.cn/desktop/image/ap_type_act0.png")
          no-repeat center;
      }
      &.ap_type_act1 {
        background: url("https://downloads.whzhjy.cn/desktop/image/ap_type_act1.png")
          no-repeat center;
      }
      &.ap_type_act2 {
        background: url("https://downloads.whzhjy.cn/desktop/image/ap_type_act2.png")
          no-repeat center;
      }
      &.ap_type_act3 {
        background: url("https://downloads.whzhjy.cn/desktop/image/ap_type_act3.png")
          no-repeat center;
      }
      &.ap_type_act4 {
        background: url("https://downloads.whzhjy.cn/desktop/image/ap_type_act4.png")
          no-repeat center;
      }
    }
    p {
      color: #262626;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #262626;
      line-height: 27px;
    }
    &:hover {
      p {
        color: #368fff;
      }
    }
    .actItem {
      color: #368fff;
    }
  }
  .actItemBt {
    border-bottom: 3px #368fff solid;
  }
  .application_item {
    padding: 33px 87px 42px;
    display: flex;
    div {
      cursor: pointer;
      text-align: center;
      margin-right: 67px;
      img {
        width: 68px;
        height: 68px;
      }
      p {
        margin-top: 10px;
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #262626;
        line-height: 21px;
        max-width: 85px;
      }
      &:hover {
        p {
          color: #368fff;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.information {
  .information_cnt {
    background: #ffffff;
    padding: 24px 43px 24px 24px;
    display: flex;
    justify-content: space-between;
  }
}
.information_cnt_left {
  width: 460px;
  height: 312px;
  ::v-deep .el-carousel__indicators {
    left: auto;
    right: 10px;
    transform: translateX(0);
    .el-carousel__button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
}
.notice {
  display: flex;
  justify-content: space-between;
  padding: 62px 0 25px;
}
.bulletin {
  width: 356px;
  .bulletin_cnt {
    background: #fff;
    min-height: 190px;
    .tzgg_item {
      padding: 16px 0;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #262626;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px dotted #dadee9;
      cursor: pointer;
      div:nth-of-type(1) {
        width: 52px;
        height: 52px;
        color: #fff;
        background: #f4f5f7;
        border-radius: 4px;
        padding-top: 7px;
        p {
          text-align: center;
          font-size: 24px;
          line-height: 24px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 400;
          color: #262626;
        }
        p:last-child {
          font-size: 10px;
          margin-top: 3px;
          color: #8c8c8c;
          line-height: 10px;
        }
      }
      div:nth-of-type(2) {
        width: 293px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      &:hover {
        div:nth-of-type(1) {
          background: linear-gradient(159deg, #2d7fff 0%, #6aacff 100%);
          p {
            color: #fff;
          }
        }
        div:nth-of-type(2) {
          color: #368fff;
        }
      }
      &:last-child {
        border: none;
      }
    }
  }
}
.sc_news {
  width: 798px;
  .sc_news_cnt {
    display: flex;
    justify-content: space-between;
    padding: 24px 0 0;
    .sc_news_cnt_left {
      width: 386px;
      height: 284px;
      ::v-deep .el-carousel__indicators--horizontal {
        bottom: 10px;
        left: auto;
        right: 7px;
        transform: none;
        li {
          padding: 12px 2px;
        }
        button {
          width: 16px;
        }
      }
    }
    .sc_news_cnt_rit {
      width: 388px;
      cursor: pointer;
      div:first-child {
        cursor: pointer;
        padding: 7px 0 15px;
        border-bottom: 1px dotted #dadee9;
        margin-bottom: 15px;
        p:nth-of-type(1) {
          font-size: 18px;
          font-weight: 500;
          color: #262626;
          padding-bottom: 10px;
          max-width: 388px;
        }
        p:nth-of-type(2) {
          font-size: 12px;
          font-weight: 400;
          color: #8c8c8c;
          line-height: 22px;
          height: 45px;
          width: 388px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .news_rit_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 36px;
        color: #262626;
        p:first-child {
          max-width: 310px;
          font-size: 14px;
        }
        p:last-child {
          font-size: 12px;
          color: #8c8c8c;
        }
        &:hover {
          color: #368fff;
        }
      }
    }
  }
  .news_item {
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
      height: 236px;
      display: block;
    }
    div {
      line-height: 48px;
      padding: 0 23px;
      color: #fff;
      font-size: 14px;
      background: rgba(0, 0, 0, 0.46);
      p {
        max-width: 265px;
      }
    }
  }
}
.noData {
  width: 100%;
  height: 300px;
  background: url("https://downloads.whzhjy.cn/desktop/image/nodata.png") no-repeat center
      center,
    #fff;
}
.bayWindow {
  position: absolute;
  top: 579px;
  left: 8%;
  z-index: 9998;
  img {
    display: block;
    width: 168px;
    height: 240px;
    border-radius: 4px;
    cursor: pointer;
  }
  i {
    color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 22px;
    cursor: pointer;
  }
}
.el-dialog__wrapper {
  .tips_btn {
    background: linear-gradient(270deg, #70afff 0%, #197fff 100%);
    border-radius: 25px;
    padding: 10px 62px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 20px;
  }
  ::v-deep .el-dialog {
    box-shadow: 0px 4px 40px 0px rgba(39, 59, 82, 0.2);
    border-radius: 16px;
    background: url("../assets/image/tips_dia_bg.png") no-repeat, #fff;
    background-size: 100%;
  }
  ::v-deep .el-dialog__header {
    text-align: center;
    padding: 40px 0 0;
  }
  ::v-deep .el-dialog__footer {
    text-align: center;
    padding: 24px 0;
  }
  ::v-deep .el-dialog__body {
    padding: 0px 40px 0;
    div {
      p:nth-of-type(1) {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #262626;
        line-height: 22px;
      }
      p:nth-of-type(2) {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0071ff;
        line-height: 20px;
        padding: 8px 0 16px;
      }
      p:nth-of-type(3) {
        background: #f1f9ff;
        border-radius: 4px;
        padding: 15px 12px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #93c4ff;
        line-height: 17px;
      }
    }
  }
}
</style>
