<template>
  <div class="smart-classroom">
    <div class="jxjy zhkt">
      <div class="content_tit">
        <div>
          <img src="../../assets/image/zhktjg.png" alt="" />
        </div>
        <div class="more" @click="open()">
          <img src="https://downloads.whzhjy.cn/desktop/image/more.png" alt="" />
        </div>
      </div>
      <div class="jxjy_cnt zhkt_cnt">
        <el-table :data="tableData" stripe>
          <el-table-column
            prop="orgName"
            label="区县"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="schoolCount"
            label="学校总数"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="subjectRateCount"
            label="学科达标学校数"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="subjectRate"
            label="学科达标率"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="courseRateCount"
            label="课时达标学校数"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="courseRate"
            label="课时达标率"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="courseSuccessCount"
            label="学校和课时达标学校数"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="courseSuccessRate"
            label="学校应用达标率"
            align="center"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import logMix from "../../mixin/index.js";
export default {
  name: "smart-classroom",
  props: {},
  components: {},
  mixins: [logMix],
  data() {
    return {
      tableData: [],
    };
  },
  computed: mapState({
    isLogin: (state) => state.user.isLogin,
    token: (state) => state.user.token,
  }),
  mounted() {
    this.getZhTable();
  },
  methods: {
    open(url) {
      if (!this.isLogin) {
        this.$message.warning("请登录后查看");
        return false;
      }
      this.appLog("智慧课堂监管");
      window.open(
        `/desktop-api/areaSchool/getStatic?token=${this.token}&appkey=${process.env.VUE_APP_SSO_appKey}`
      );
    },
    // 智慧课堂table
    getZhTable() {
      this.$api
        .appStatistics2()
        .then((res) => {
          try {
            if (res) {
              console.log(res);
              this.tableData = res.data;
              this.tableData.forEach((item, index) => {
                // item.rank = index + 1;
                for (let key in item) {
                  key.indexOf("Rate") > -1 && key.indexOf("Count") === -1
                    ? (item[key] = (item[key] * 100).toFixed(2) + "%")
                    : "";
                }
              });
            }
          } catch (err) {
            console.log(err);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.smart-classroom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // https://downloads.whzhjy.cn/desktop/image/data_center_bg.png
  background: linear-gradient(180deg, #ebf6ff 0%, #ffffff 100%);
  // url("https://downloads.whzhjy.cn/desktop/image/ygyk_bg.png") no-repeat top center,
  padding: 36px 0;
  position: relative;
}
.jxjy {
  position: relative;
  width: 1200px;
  .content_tit {
    div:nth-of-type(1) img {
      width: 166px;
    }
  }
  .jxjy_cnt {
    margin-top: 20px;
    background: linear-gradient(180deg, #e8effb 0%, #ffffff 100%);
    box-shadow: 4px 4px 20px 0px rgba(126, 150, 193, 0.15);
    border-radius: 8px;
    border: 2px solid #ffffff;
    padding: 24px;
  }
  .zhkt_cnt {
    padding: 35px 30px;
    background: linear-gradient(181deg, #e8effb 0%, #ffffff 70%);
    ::v-deep .el-table {
      border: 1px solid #dce7fc;
      border-right: none;
      td,
      th.is-leaf {
        border: none;
      }
      .el-table__row--striped {
        border-radius: 23px;
        td {
          background-color: #f5fafe;
        }
      }
      .el-table__row {
        td {
          font-size: 14px;
          color: #595959;
          border-right: 1px solid #dce7fc;
        }
      }
      .el-table__header {
        tr {
          th {
            background: rgba(108, 157, 255, 0.18);
            font-size: 14px;
            color: #262626;
            font-weight: 500;
            border-right: 1px solid #dce7fc;
          }
        }
      }
    }
    .el-table::before {
      height: 0;
    }
    ::v-deep .el-table__fixed::before {
      height: 0;
    }
  }
}
</style>
