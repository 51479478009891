<template>
  <div class="school_overview">
    <div class="mb_bg" ref="mb_bg"></div>
    <div class="mb_color"></div>
    <div class="overview_cnt">
      <div class="content_tit w1200">
        <div>
          <img src="https://downloads.whzhjy.cn/desktop/image/xxfc_icon.png" alt="" />
        </div>
        <div class="more" @click="open(0,'/school-news/index/schoolList')">
          <img src="https://downloads.whzhjy.cn/desktop/image/more_w.png" alt="" />
        </div>
      </div>
      <div class="overview_detail">
        <img class="detail_preview" :src="schoolDetail.photo" alt="">
        <div>
          <p><img :src="`${schoolDetail.logo ? schoolDetail.logo : 'https://whzhjy.cn/school-news/resources/static/img/school-icon2.png'}`" alt=""> <span>{{ schoolDetail.name }}</span></p>
          <p>{{ schoolDetail.description }}</p>
          <p @click="open(0,schoolDetail.url)">进入学校</p>
        </div>
      </div>
      <div class="overview_swp">
        <div class="swiper-container" id="swiper_container_two">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide school_card"
              v-for="(item, index) in schoolList"
              :key="'0' + index"
              @click="changeInSchool(item)"
            >
            <div>
              <img :src="`${item.logo ? item.logo : 'https://whzhjy.cn/school-news/resources/static/img/school-icon2.png'}`" alt="">
              <p class="overText" :title="item.name">{{ item.name }}</p>
            </div>
            </div>
          </div>
        </div>
        <div
          class="swiper-button-prev swiper-button-prev-two swiper-button-prev-w"
          slot="button-prev"
        ><i class="el-icon-arrow-left"></i></div>
        <div
          class="swiper-button-next swiper-button-next-two swiper-button-next-w"
          slot="button-next"
        ><i class="el-icon-arrow-right"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import logMix from "../../mixin/index.js"
export default {
  name: "school_overview",
  props: {},
  mixins: [logMix],
  components: {},
  data() {
    return {
      schoolDetail: {},
      schoolList: [],
      swiper_two: null,
    };
  },
  computed: mapState({
    homePage_data: state => state.user.homePage_data,
  }),
  mounted() {
    this.schoolList = this.homePage_data.xxfc
    // this.schoolDetail = this.schoolList[0]
    this.changeInSchool(this.schoolList[0])
    this.$nextTick(()=>{
      this.initSwiperTwo()
    })
  },
  methods: {
    initSwiperTwo() {
      this.swiper_two = new Swiper("#swiper_container_two", {
        slidesPerView: 6,
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        // loop: true, // 循环模式选项 loop开启复制的元素不会同时复制dom绑定的事件 若要开启只能只用swiper的onClick回调函数
        autoplay: 5000,
        autoplayDisableOnInteraction: false,
        // 如果需要分页器
        prevButton: ".swiper-button-prev-two",
        nextButton: ".swiper-button-next-two",
        onSlideChangeEnd: (swiper) => {
          this.changeInSchool(this.schoolList[swiper.activeIndex])
        }
      });
    },
    changeInSchool(item){
      this.schoolDetail = item
      this.$refs.mb_bg.style.background = `url(${item.photo}) no-repeat`
      this.$refs.mb_bg.style.backgroundSize = `100% 100%`
    },
    open(type,url){
      if(type === 1){

      }else{
        this.appLog('学校风采')
        window.open(url)
      }
    }
  },
};
</script>

<style scoped lang="scss">
.school_overview{
  padding-top: 32px;
  width: 100%;
  height: 550px;
  position: relative;
  // backdrop-filter: blur(50px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .mb_bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-size: 100%;
    filter: blur(10px);
  }
  .mb_color{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #74A5FF 0%, #000000 100%);
    opacity: .2;
  }
}
.overview_cnt{
  width: 1320px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.w1200{
  width: 1200px;
}
.overview_detail{
  margin-top: 24px;
  display: flex;
  .detail_preview{
    width: 600px;
    height: 360px;
  }
  div{
    position: relative;
    padding: 34px 37px;
    background: url('https://downloads.whzhjy.cn/desktop/image/school_detail_bg.png') no-repeat bottom center, linear-gradient(0deg, #74B8FF 0%, #004AFF 100%);
    text-align: left;
    color: #fff;
    width: 600px;
    p:nth-of-type(1){
      font-size: 20px;
      font-weight: bold;
      line-height: 20px;
      display: flex;
      align-items: center;
      img{
        width: 56px;
        height: 56px;
        margin-right: 9px;
      }
    }
    p:nth-of-type(2){
      text-align: left;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      line-height: 24px;
      max-height: 120px;
      margin-top: 23px;
      overflow: hidden;
      display: -webkit-box;
      text-overflow:ellipsis;
      -webkit-line-clamp:5;
      -webkit-box-orient: vertical;
      position: relative;
    }
    p:nth-of-type(3){
      color: #fff;
      font-size: 14px;
      position: absolute;
      border: 1px solid #FFFFFF;
      padding: 11px 30px;
      bottom: 56px;
      left: 36px;
      cursor: pointer;
    }
  }
}
.overview_swp{
  width: 1320px;
  height: 100px;
  position: relative;
  #swiper_container_two{
    width: 1200px;
  }
  .school_card{
    width: 200px;
    height: 96px;
    cursor: pointer;
    background: #fff;
    div{
      border-right: 1px solid rgba(54, 143, 255, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    img{
      width: 32px;
      height: 32px;
      margin-right: 9px;
    }
    p{
      font-size: 14px;
      font-weight: bold;
      color: #262626;
      max-width: 150px;
    }
    &:hover{
      p{
        color: #368FFF;
      }
    }
  }
  .swiper-button-next{
    right: 6px;
  }
  .swiper-button-prev{
    left: 6px;
  }
  .swiper-slide-active{
    div{
      background: rgba(54, 143, 255, 0.1);
      border-bottom: 4px solid #368FFF;
    }
    // box-shadow: 0px 0px 10px 0px rgba(0, 97, 255, .7);
  }
}
</style>
