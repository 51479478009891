<template>
  <div class="data_center">
    <div class="sjzx_cnt">
      <div class="content_tit">
        <div>
          <img
            src="https://downloads.whzhjy.cn/desktop/image/data_center_icon.png"
            alt=""
          />
          <div class="data_type">
            <div
              v-for="item in dataTypeList"
              :key="item.val"
              :class="dataAct === item.val ? 'typeAct' : ''"
              @click="cgDataType(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="more" @click="open('/data-center')">
          <img src="https://downloads.whzhjy.cn/desktop/image/more.png" alt="" />
        </div>
      </div>
      <div class="sjzx_itd">
        <div>
          <p>{{ allData[dataAct].tit }}</p>
          <p>{{ allData[dataAct].context }}</p>
          <p @click="open('/data-center')">查看更多</p>
        </div>
      </div>
      <img
        class="data_center_img"
        src="https://downloads.whzhjy.cn/desktop/image/data_center_img.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "data_center",
  props: {},
  components: {},
  data() {
    return {
      dataAct: "zhjx",
      dataTypeList: [
        {
          name: "智慧教学",
          val: "zhjx",
        },
        {
          name: "智慧学习",
          val: "zhxx",
        },
        {
          name: "智慧文化",
          val: "zhwh",
        },
        {
          name: "智慧生活",
          val: "zhsh",
        },
        {
          name: "智慧管理",
          val: "zhgl",
        },
      ],
      allData: {
        zhjx: {
          tit: "智慧教学",
          context:
            "针对常态化教学活动，场景上从课前备课-授课互动-测练组卷-作业练习，全流程分析教师教学和学生学习数据，同时结合智慧课堂应用和监管数据，促成教育教学的过程性学业动态和区域数据总览分析，为芜湖市区域、学校实现教师教学的因材施教的目标愿景助力。",
        },
        zhxx: {
          tit: "智慧学习",
          context:
            "结合平台中丰富的教研活动、良好的资源应用、个性学习现状，智能采集多场景的过程和结果数据，对区域数据深度洞察，建立、健全区域在线教研体系，提升教师综合教学能力和素质，丰富教师教学策略和行为，对教师成长，学生个性化发展、学习具有重要意义。",
        },
        zhwh: {
          tit: "智慧文化",
          context:
            "完善学校文化环境建设，实现各类信息资讯服务多样化，反映丰富多彩的师生校园生活，弘扬社会主流价值观，传递学校发展正能量。借助信息化的方式构建教师、学生、家长三类核心用户画像，统计分析用户使用平台内的相关系统的应用数据，提升数据赋能的目标。",
        },
        zhsh: {
          tit: "智慧生活",
          context:
            "借助软硬件互联互通的底层能力，利用校园一卡通、体质健康监测、智慧体育课等硬件设备，采集超市消费、水电缴费、门禁通行、体质测试、体育课程开展等多场景的校园生活数据，多方位、多角度构建安全和健康的学校生活服务新体系，营造智慧生活新生态。",
        },
        zhgl: {
          tit: "智慧管理",
          context:
            "统一管理各应用系统的使用表现数据、学校智慧管理应用数据、学校办学条件和区域均衡发展数据，通过丰富的统计维度对应用的各项指标进行具象化分析，为应用系统的管理、推广、运营提供有效的量化依据，为芜湖教育大数据应用成效的良好发展奠定坚实的基石。",
        },
      },
    };
  },
  computed: mapState({}),
  mounted() {
    // this.jyzx_data = this.homePage_data.yyzx
  },
  methods: {
    cgDataType(item) {
      this.dataAct = item.val;
    },
    open(url) {
      window.open(url);
    },
  },
};
</script>

<style scoped lang="scss">
.data_center {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 466px;
  // https://downloads.whzhjy.cn/desktop/image/data_center_bg.png
  background: url("https://downloads.whzhjy.cn/desktop/image/data_center_bg.png")
      no-repeat top center,
    linear-gradient(180deg, #ebf6ff 0%, #ffffff 100%);
  background-size: 100% 100%;
  padding: 36px 0;
  position: relative;
}
.sjzx_cnt {
  width: 1200px;
  height: 100%;
  position: relative;
}
.data_center_img {
  position: absolute;
  top: 70px;
  right: -10px;
  width: 429px;
  height: 366px;
}
.sjzx_itd {
  div {
    width: 779px;
    padding: 44px 38px 0px;
    background: url("https://downloads.whzhjy.cn/desktop/image/data_center_item_bg.png")
      no-repeat top center;
    margin-top: 72px;
    background-size: 100% 100%;
    position: relative;
    p:nth-of-type(1) {
      text-align: center;
      font-size: 24px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #262626;
      line-height: 36px;
      background: url("https://downloads.whzhjy.cn/desktop/image/data_center_item_tit.png")
        no-repeat center center;
      background-size: 238px 7px;
    }
    p:nth-of-type(2) {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #595959;
      line-height: 32px;
      padding: 17px 0;
      height: 142px;
    }
    p:nth-of-type(3) {
      position: absolute;
      bottom: 20px;
      right: 50px;
      font-size: 16px;
      color: #368fff;
      cursor: pointer;
    }
  }
}
</style>
