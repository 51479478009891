/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  MessageBox,
  Message
} from 'element-ui';
import {
  rucdm_login
} from './whdjg.js'
let baseUrl = ''
window.location.origin === 'https://whzhjy.cn' ? baseUrl = window.location.origin + '/' : baseUrl = 'https://wuhutestbasic.changyan.cn' + '/qxpt_third/'
const _this = {
  iconUrl: 'https://download.whzhjy.cn/qxpt/thirdApp/',
  jumpUrl: baseUrl
}
const user = JSON.parse(sessionStorage.getNavbarConfigData || '{}')

export function openApp(title) {
  if (title === '人大复印报刊') {
    rucdm_login()
    window.location.reload()
    return false
  }

  if (title === '教学大师' || title === '影视课堂') {
    const html = buildThirdHtml(title)
    const id = title === '教学大师' ? 'jxds-wrapper' : 'yskt-wrapper'
    const width = title === '教学大师' ? '450px' : '320px'
    // showTips(id, html, function () {
    // 打开弹窗
    // layer.open({
    //   type: 1,
    //   title: title, // 标题
    //   area: [width, '260px'], // 宽高
    //   skin: 'popup-teach-master',
    //   shade: 0.4, // 遮罩透明度
    //   move: false,
    //   content: $('#' + id), // 支持获取DOM元素
    //   end: function () {
    //     $('#' + id).css('display', 'none')
    //   }
    // })
    MessageBox.alert(html, title, {
      dangerouslyUseHTMLString: true,
      showConfirmButton: false,
      customClass: 'jxds' + width
    })
    // })
    return false
  }
  if (title == '中国知网') {
    const html = buildThirdHtml(title)
    const id = 'zgzwBox'
    // showTips(id, html, function () {
    //   // 打开弹窗
    //   layer.open({
    //     type: 1,
    //     title: title, // 标题
    //     area: ['400px', '220px'], // 宽高
    //     skin: 'popup-teach-master',
    //     shade: 0.4, // 遮罩透明度
    //     move: false,
    //     content: $('#' + id), // 支持获取DOM元素
    //     end: function () {
    //       $('#' + id).css('display', 'none')
    //     }
    //   })
    // })
    MessageBox.alert(html, '中国知网', {
      dangerouslyUseHTMLString: true,
      showConfirmButton: false,
      // customClass: '' + width
    })
    return false
  }
  if (title === '火花学院' || title === '百度文库') {
    const html = buildThirdHtml(title)
    var id = 'pop-wrapper-hhxy'
    if (title === '百度文库') {
      var id = 'pop-wrapper-bdwk'
    }
    // showTips(id, html, function () {
    //   // 打开弹窗
    //   layer.open({
    //     type: 1,
    //     title: title + '跳转提示', // 标题
    //     area: ['240px', '180px'], // 宽高
    //     skin: 'popup-teach-master',
    //     shade: 0.4, // 遮罩透明度
    //     move: false,
    //     content: $('#' + id), // 支持获取DOM元素
    //     end: function () {
    //       $('#' + id).css('display', 'none')
    //     }
    //   })
    // })
    MessageBox.alert(html, title, {
      dangerouslyUseHTMLString: true,
      showConfirmButton: false,
      // customClass: '' + width
    })
    return false
  }
  if (jump(title)) {
    return false
  }
  return true
}
export function jump(title) {
  let url = false
  let isWhAPP = false
  if (title === '十分钟课堂') {
    url = _this.jumpUrl + 'area/minutes_timeMinutesClass.action'
    isWhAPP = true
  }
  if (title === '时代优课') {
    const navTitle = JSON.parse(sessionStorage.getNavbarConfigData || '{}')
    const username = navTitle.user.username
    url = 'http://www.timeep.com/whResource.do?type=sdyk&loginName=' + username
  }
  if (title === '云图书馆') {
    const navTitle = JSON.parse(sessionStorage.getNavbarConfigData || '{}')
    const username = navTitle.user.username
    url = 'http://www.timeep.com/whResource.do?type=ytsg&loginName=' + username
  }
  if (title === '乐乐学堂') {
    url = _this.jumpUrl + 'open/leleRes_main.action'
    isWhAPP = true
  }
  if (title === '博看电子期刊') {
    url = 'http://zq.bookan.com.cn/?t=index&id=24855'
  }
  if (title === '升学e网通') {
    url = _this.jumpUrl + 'area/ewtsx_ewtsxClass.action'
    isWhAPP = true
  }
  if (title === '学科网') {
    url = 'https://oauthproxy.xkw.com/wuhu/authentication?schoolId='
  }
  if (isWhAPP) {
    $.ajax({
      url: baseUrl + 'manage/login_out.action?t=' + Math.random(),
      type: 'get',
      success: function () {},
      complete: function () {
        window.open(url)
      }
    })
  } else {
    url && window.open(url)
  }
  return url
}
// 显示提示
export function showTips(id, html, showTipFun) {
  if (typeof $('#' + id).html() === 'undefined') {
    $('body').append(html).ready(function () {
      if (typeof (showTipFun) === 'function') {
        showTipFun()
      }
    })
  } else {
    if (typeof (showTipFun) === 'function') {
      showTipFun()
    }
  }
}

export function buildThirdHtml(title) {
  const navTitle = JSON.parse(sessionStorage.getNavbarConfigData || '{}')
  const username = navTitle.user.username
  let html = ''
  if (title === '教学大师') {
    html += '<div id="jxds-wrapper">'
    html += '<ul class="drag-item-list">'
    html += '<div>'
    html += '<li data-id="1" style="cursor: pointer;" onclick=" window.open(\'http://www.timeep.com/whResource.do?type=jxds&stage=%E5%B0%8F%E5%AD%A6&loginName=' + username + '\')">'
    html += '<img src="' + _this.iconUrl + 'jxds-xx.png" alt="" >'
    html += '<p>小学版教学大师</p>'
    html += '</li>'
    html += '<li data-id="2" style="cursor: pointer;" onclick="window.open(\'http://www.timeep.com/whResource.do?type=jxds&stage=%E5%88%9D%E4%B8%AD&loginName=' + username + '\')">'
    html += '<img src="' + _this.iconUrl + 'jxds-cz.png" alt="" >'
    html += '<p>初中版教学大师</p>'
    html += '</li>'
    html += '<li data-id="3" style="cursor: pointer;" onclick="window.open(\'http://www.timeep.com/whResource.do?type=jxds&stage=%E9%AB%98%E4%B8%AD&loginName=' + username + '\')">'
    html += '<img src="' + _this.iconUrl + 'jxds-gz.png" alt="" >'
    html += '<p>高中版教学大师</p>'
    html += '</li>'
    html += '</div>'
    html += '</ul>'
    html += '</div'
  } else if (title === '影视课堂') {
    html += '<div id="yskt-wrapper">'
    html += '<ul class="drag-item-list">'
    html += '<div>'
    html += '<li data-id="1" style="cursor: pointer;" onclick="window.open(\'http://www.timeep.com/whResource.do?type=yskt&stage=%E5%B0%8F%E5%AD%A6&loginName=' + username + '\')">'
    html += '<img src="' + _this.iconUrl + 'sdyk.png" alt="" >'
    html += '<p>小学版影视课堂</p>'
    html += '</li>'
    html += '<li data-id="2" style="cursor: pointer;" onclick="window.open(\'http://www.timeep.com/whResource.do?type=yskt&stage=%E5%88%9D%E4%B8%AD&loginName=' + username + '\')">'
    html += '<img src="' + _this.iconUrl + 'yskt-cz.png" alt="" >'
    html += '<p>初中版影视课堂</p>'
    html += '</li>'
    html += '</div>'
    html += '</ul>'
    html += '</div'
  } else if (title === '火花学院' || title === '百度文库') {
    if (title === '百度文库') {
      html += '<div id="pop-wrapper-bdwk">'
    } else {
      html += '<div id="pop-wrapper-hhxy">'
    }
    html += '<div style="text-indent: 2em;margin: 11px 11px 0px 9px;line-height:23px;">'
    html += '<span style="font-size:14px;">'
    html += '不得用于任何商业用途或再进行任何形式的二次分发或以其他任何形式侵犯原权利方或第三方的合法权益。'
    html += '</span>'
    html += '</div>'
    html += '<div style="text-align:center;padding-top: 20px;font-size: 15px;line-height: 15px;">'
    if (title === '百度文库') {
      html += '<a type="button" style="color:#368FFF" href="javascript:;" onclick="openBaiDuDocUrl();">确定</a>'
    } else {
      html += '<a type="button" href="https://www.huohuaschool.com/thirdparty/page?spm=whzhjy" style="color:#368FFF" target="_blank">确定</a>'
    }
    html += '</div>'
    html += '</div>'
  } else if (title === '中国知网') {
    html += '<div class="dropbox" id="zgzwBox" style="padding: 20px;">'
    html += '<li style="margin-bottom: 8px;"><a onclick="thirdZwLogin(1)" style="cursor: pointer;" target="_blank">中国基础教育资源总库</a></li>'
    //html += '<li style="margin-bottom: 8px;"><a onclick="thirdZwLogin(2);layer.closeAll();" style="cursor: pointer;" target="_blank">CNKI经典导读</a></li>'
    //html += '<li style="margin-bottom: 8px;"><a onclick="thirdZwLogin(3);layer.closeAll();" style="cursor: pointer;" target="_blank">中小学工具书总库</a></li>'
    html += '<div class="hastips">'
    html += '<li id="yjxxx" style="cursor: pointer;" onmouseover="showZWTip(1)" onmouseleave="showZWTip(0)""><a onclick="thirdZwLogin(3)"  target="_blank">知网研学平台</a>'
    html += '<div id="yjxxxTips" style="display:none;color: #c4c4c4" class="tips-content">(使用前请注册账号进入个人中心关联机构口令：W1X4VL&nbsp;)</div></li>'
    html += '</div>'
    html += '</div>'
  }
  return html
}
export function showZWTip(flag) {
  if (flag == 1) {
    $('#yjxxxTips').show()
  } else {
    $('#yjxxxTips').hide()
  }
}

export function thirdZwLogin(obj) {
  $.ajax({
    type: 'get',
    url: baseUrl + 'manage/login_thirdZwLoginJSONP.action',
    dataType: 'jsonp',
    success: function (data) {
      const result = data.result // $.parseJSON(data).result;
      if (result != null) {
        const resultArr = result.split('@')
        if (resultArr[0] === '0') {
          // layer.alert(resultArr[1])
          Message.warning(resultArr[1])
        } else {
          let returnUrl
          switch (obj) {
            case 1:
              // returnUrl = "http://www.cfed.cnki.net";
              returnUrl = 'https://r.cnki.net/KNS/brief/result.aspx?dbprefix=CFED'
              break
            case 2:
              returnUrl = 'http://yd.cnki.net'
              break
            case 3:
              // returnUrl = "http://zref.cnki.net";
              returnUrl = 'https://x.cnki.net'
              break
            case 4:
              returnUrl = 'https://x.cnki.net'
              break
          }
          window.open('https://my.cnki.net/IntegrateLogin/Redirect.ashx?token=' + resultArr[1] + '&ReturnUrl=' + returnUrl)
        }
      }
    }
  })
}

export function openBaiDuDocUrl() {
  get_wk_token(function (token) {
    if ($.trim(token).length == 0) {
      return
    }
    const url = 'https://zyk.baidu.com/ndtobbdedu/whedu/home?token={token}&product=whedu'.replace('{token}', token)
    window.open(url)
  })
}

export function get_wk_token(callBack) {
  const navTitle = JSON.parse(sessionStorage.getNavbarConfigData || '{}')
  const username = navTitle.user.username
  const now = new Date()
  const date = now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate()
  const storeKey = 'baiduwenku_token_' + username + '_' + date
  let token = localStorage.getItem(storeKey)
  if (token) {
    callBack(token)
    return
  }
  $.ajax({
    url: baseUrl + 'manage/login_getWenkuTokenJSONP.action',
    dataType: 'jsonp',
    jsonpCallback: 'callback',
    async: false,
    success: function (result) {
      let resultObj = {}
      if (typeof result === 'object') {
        resultObj = result
      } else {
        try {
          resultObj = eval('(' + result + ')')
        } catch (e) {
          // layer.alert('请求出错，请重试')
          Message.error('请求出错，请重试')
          return
        }
      }
      switch (resultObj.status.code) {
        case 0:
          token = resultObj.data.wkAccessToken
          break
        case 100000:
          token = ''
          // layer.alert('未登录，请先登录。')
          Message.error('未登录，请先登录。')
          break
        case 110002:
          token = ''
          // layer.alert('参数错误，请刷新页面重试。')
          Message.error('参数错误，请刷新页面重试。')
          break
        case 110003:
          token = ''
          // layer.alert('服务错误，请稍后重试。')
          Message.error('服务错误，请稍后重试。')
          break
        case 110004:
          token = ''
          // layer.alert('超出今日服务限制，请明日再试。')
          Message.error('超出今日服务限制，请明日再试。')
          break
        default:
          token = ''
          // layer.alert('未知错误，请刷新页面重试。')
          Message.error('未知错误，请刷新页面重试。')
          break
      }

      if ($.trim(token).length > 0) {
        localStorage.setItem(storeKey, token)
        callBack(token)
      }
    },
    error: function (e) {}

  })
}

export function newBlankJump(href) {
  // 自动跳转的当前页面刷新
  // window.location.href = href
  window.open(href)
}
window.showZWTip = showZWTip
window.thirdZwLogin = thirdZwLogin
window.openBaiDuDocUrl = openBaiDuDocUrl